import React from 'react'
import { Nextb, posimg } from '../../assets'

function PerfectPosition() {
    return (
        <div className='PerfectPostion wow fadeInUp' data-wow-delay="0.5s">

            <div className='PostionContainer row'>

                <div className='col-md-6 d-flex align-items-center'>
                    <div className='PositionText'>

                        <h2>Can't find the perfect position? Share your CV 

                        </h2>

                        <button type='submit' className="btn mt-5 btn-primary  btn-visonimpout  btn-lg"><span>Upload CV</span> <img className='mt-1 imgNext' src={Nextb} alt="" /></button>


           
                    </div>

                </div>

                <div className='col-md-6 mobileAdjust'>
                <img src={posimg} alt="" className='imgPosLarge' />


                </div>



            </div>
        </div>
    )
}

export default PerfectPosition