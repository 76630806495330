import React from 'react'
import LogosSlider from '../LogosSlider'
import VisonAndImapact from './VisonAndImapact'
import TransformingIdeasSection from './TransformIdeas'
import SingleComponentTrans from './TransIdeaMid'
import FuelInvoation from './FuelInvoation'
import TransIdeaBlue from './TransIdeaBlue'
import AreUReady from './AreUReady'
import ContactForm from './GETInTouch'
import BannerSection from './BannerSection'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


function Home() {
  return (
    <div className='container-fluid overflowHide px-0  mt-3'>
      <BannerSection/>
      <LogosSlider />
      <SingleComponentTrans/>
       <VisonAndImapact/>
       <TransformingIdeasSection/>
     
       <FuelInvoation/>
       {/* <TransIdeaBlue/> */}
       <AreUReady/>
       <ContactForm/>
    </div>

  )
}

export default Home