import React from 'react'
import { Arrow, Devops, Dm1, Gen1, MobileSer, Sw1, UiUx, serviceD1 } from '../../assets'
import { useLocation } from 'react-router-dom';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function DetailBanner() {

    const query = useQuery();
    const searchTerm = query.get('ServiceType');
    return (
        <>
            {searchTerm === 'web_app_development' ? (
                <div className='DetailBanner wow fadeInUp' data-wow-delay="0.5s">
                    <h1>Web App Development</h1>
                    <img src={Arrow} alt="" className='mt-4' />
                    <div className='mt-4 px-4'>
                        <img src={serviceD1} alt="" className='Image_Ds' />
                    </div>
                </div>
            ) : null}
            {searchTerm === 'mobile_app_development' ? (
                <div className='DetailBanner wow fadeInUp' data-wow-delay="0.5s">
                    <h1>Mobile App Development</h1>
                    <img src={Arrow} alt="" className='mt-4' />
                    <div className='mt-4 px-4'>
                        <img src={MobileSer} alt="" className='Image_Ds' />
                    </div>
                </div>
            ) : null}
            {searchTerm === 'devops' ? (
                <div className='DetailBanner wow fadeInUp' data-wow-delay="0.5s">
                    <h1 className='text-center d-block'>DevOps</h1>
                    <img src={Arrow} alt="" className='mt-4' />
                    <div className='mt-4 px-4'>
                        <img src={Devops} alt="" className='Image_Ds' />
                    </div>
                </div>
            ) : null}
            {searchTerm === 'Ui-Ux-design' ? (
                <div className='DetailBanner wow fadeInUp' data-wow-delay="0.5s">
                    <h1 className='text-center d-block'>UX/UI Design</h1>
                    <img src={Arrow} alt="" className='mt-4' />
                    <div className='mt-4 px-4'>
                        <img src={UiUx} alt="" className='Image_Ds' />
                    </div>
                </div>
            ) : null}
            {searchTerm === 'digital_marketing' ? (
                <div className='DetailBanner wow fadeInUp' data-wow-delay="0.5s">
                    <h1 className='text-center d-block'>Digital <br /> Marketing</h1>
                    <img src={Arrow} alt="" className='mt-4' />
                    <div className='mt-4 px-4'>
                        <img src={Dm1} alt="" className='Image_Ds' />
                    </div>
                </div>
            ) : null}
            {searchTerm === 'software_testing' ? (
                <div className='DetailBanner wow fadeInUp' data-wow-delay="0.5s">
                    <h1 className='text-center d-block'>Software <br /> Testing
                    </h1>
                    <img src={Arrow} alt="" className='mt-4' />
                    <div className='mt-4 px-4'>
                        <img src={Sw1} alt="" className='Image_Ds' />
                    </div>
                </div>
            ) : null}
              {searchTerm === 'Gen_AI' ? (
                <div className='DetailBanner wow fadeInUp' data-wow-delay="0.5s">
                    <h1 className='text-center d-block'>Generative AI
                    </h1>
                    <img src={Arrow} alt="" className='mt-4' />
                    <div className='mt-4 px-4'>
                        <img src={Gen1} alt="" className='Image_Ds' />
                    </div>
                </div>
            ) : null}
        </>


    )
}

export default DetailBanner