
import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Tooltip,useMap } from 'react-leaflet';
import L from 'leaflet';
import { Mappin, can, ind, uae } from '../../assets';


function GoogleAddress() {
    const invisibleIcon = L.divIcon({
        html: '', // No HTML content
        className: 'hidden-icon', // CSS class to ensure it's invisible
        iconSize: [0, 0], // Set size to zero to avoid rendering any icon
        iconAnchor: [0, 0], // Anchor point set to zero
    });

    const position = [12.915211347359591, 77.60574510538213];
    // Example coordinates (latitude, longitude)
    const locationName = 'Vikgol'; 
    const CircleLayer = ({ position }) => {
        const map = useMap();
        
        useEffect(() => {
          // Create a circle and add it to the map
          L.circle(position, {
            radius: 100, // Radius of the circle in meters
            color: 'black',
            fillColor: 'black',
            fillOpacity: 0.5,
          }).addTo(map);
        }, [map, position]);
      
        return null;
      };// Example location name
    return (
        <div className='GoogleAddress px-lg-5 wow fadeInUp' data-wow-delay="0.5s">
            <h3 className='my-3'>
            We're here to assist you <br/> Please feel free to contact us 
            </h3>

            <div className='row mt-lg-3'>
              <div className='col-md-4 mt-3'>
                <div className='designlaout'>
                  <div className='img-con'>
                  <img src={ind} alt="" />
                  </div>
                
                  <h2>India</h2>

                  <h3>Bangalore (Head Office)</h3>
                  <p>#265, 2nd floor, 6th main, 4th cross Stage-2, BTM Layout Bengaluru 560076, INDIA Email: contact@vikgol.com</p>

                </div>

              </div>
              <div className='col-md-4 mt-3'>
                <div className='designlaout'>
                  <div className='img-con'>
                  <img src={can} alt="" />
                  </div>
                
                  <h2>Canada</h2>

                  <h3>Ontario</h3>
                  <p>339 Rathburn Road W, Unit-@Ph10, ON L5B 0K6, Canada</p>

                </div>

              </div>
              <div className='col-md-4 mt-3'>
                <div className='designlaout'>
                  <div className='img-con'>
                  <img src={uae} alt="" />
                  </div>
                
                  <h2>UAE</h2>

                  <h3>Dubai</h3>
                  <p>Gtext Business Center, Office number: 2404, Shaikh Zayed Road - Dubai</p>

                </div>

              </div>

            </div>
            





        </div>


    )
}

export default GoogleAddress