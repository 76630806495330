import React from 'react'
import { Journey } from '../../assets'

function JourneyOur() {
  return (
    <div className='ourJourney wow fadeInUp' data-wow-delay="0.5s">
         
        <div className='InsideJourney  m-auto px-lg-5'>

            <h2 className='mt-4'>Our journey from inception to innovation</h2>

            <p className='mt-4 '>
            Born out of a passion for technology and a deep understanding of business needs, Vikgol has emerged as a leading software development company. Since our inception, we've been committed to delivering innovative solutions that drive growth and success for our clients. With a proven track record in fintech, edtech, and other industries, we have consistently exceeded expectations and built lasting partnerships.
            </p>

            <img src={Journey} alt="" className='mt-4' />

        </div>


    </div>
  )
}

export default JourneyOur