import React from 'react';
import Slider from 'react-slick';



const TechCarousel = ({ techStack, direction = 'left' }) => {
  // Slick settings
  const settings = {
    dots: false, // Show navigation dots
    infinite: true, // Loop slides
    speed: 2000, // Transition speed in milliseconds
    slidesToShow: 10, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll per transition
    rtl: direction === 'right',
    autoplaySpeed: 0, // Set to zero for continuous scrolling
    cssEase: 'linear', // Linear animation for constant speed
    pauseOnHover: true, 
    autoplay: true,
  
     // Use right-to-left if direction is 'right'
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 10,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <div className="tech-carousel mt-4 wow fadeInUp" data-wow-delay ="0.5s" >
      <Slider {...settings}>
        {techStack.map((tech, index) => (
          <div key={index + direction} className="tech-slide">
            <div className="tech-logo-wrapper">
              <img src={tech.src} alt={tech.alt} className="img-fluid tech-logo" />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TechCarousel;
