import React from 'react'
import { BTImage, Nextb } from '../../assets'

function TransFromBusiness() {
    return (
        <div className='TransformBusiness ps-lg-3 wow fadeInUp ' data-wow-delay ="0.5s">

            <div className='row ps-lg-5'>

                <div className='col-md-6 Ds-vikgole ps-lg-4 '>

                    <h2>
                    Transform Your Business with Vikgol's Comprehensive Digital Solutions
                    </h2>

                    <a href="https://calendly.com/harsh-46/30min?month=2024-08" target='_blank' className="btn btn-primary btn-visonimpout mt-5 mb-3   btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={Nextb} alt="" /></a>

                </div>
                <div className='col-md-6 justify-content-end d-flex'>

                    <img src={BTImage} alt="" />

                </div>

            </div>

        </div>
    )
}

export default TransFromBusiness