import React from 'react'
import { Devops1, Dm3, Flight1, Flight2, Flight3, Gen3, Sw3, Trusted, UiUx3 } from '../../assets'
import MobileTech from '../../assets/images/MobileTrust.png'
import { useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function YourTrusted() {
    const query = useQuery();
    const searchTerm = query.get('ServiceType');
    return (
        <>

            {searchTerm == 'web_app_development' ? (<div className='YourTrusted wow fadeInUp' data-wow-delay="0.5s">
                <div className='row'>
                    <div className='col-md-6 py-5'>

                        <img className='img-fluid' src={Trusted} alt="" />

                    </div>
                    <div className='col-md-6 py-5 Trustedpatner'>
                        <h2>Your trusted partner for innovative web development</h2>

                        <div className='item_container row'>
                            <div className='col-2 d-flex align-items-center  justify-content-end'>
                                <img src={Flight1} alt="" />
                            </div>
                            <div className='col-8 mt-3'>
                                <h3>UX-focused design</h3>
                                <p>We prioritize user experience to create engaging and intuitive interfaces.</p>

                            </div>

                        </div>
                        <div className='item_container row'>
                            <div className='col-2 d-flex align-items-center  justify-content-end'>
                                <img src={Flight2} alt="" />
                            </div>
                            <div className='col-8 mt-3'>
                                <h3>Discovery call</h3>
                                <p>Schedule a free consultation to understand your project goals.</p>

                            </div>

                        </div>
                        <div className='item_container row'>
                            <div className='col-2 d-flex align-items-center  justify-content-end'>
                                <img src={Flight3} alt="" />
                            </div>
                            <div className='col-8 mt-3'>
                                <h3>Agile development</h3>
                                <p>We employ a collaborative approach for efficient project execution.</p>

                            </div>

                        </div>

                    </div>

                </div>
            </div>) : null}
            {searchTerm == 'mobile_app_development' ? (<div className='YourTrusted wow fadeInUp' data-wow-delay="0.5s">
                <div className='row'>
                    <div className='col-md-6 py-5'>

                        <img className='img-fluid' src={MobileTech} alt="" />

                    </div>
                    <div className='col-md-6 py-5 Trustedpatner'>
                        <h2>Your trusted partner for innovative App development</h2>

                        <div className='item_container row'>
                            <div className='col-2 d-flex align-items-center  justify-content-end'>
                                <img src={Flight1} alt="" />
                            </div>
                            <div className='col-8 mt-3'>
                                <h3>UX-focused design</h3>
                                <p>We prioritize user experience to create engaging and intuitive interfaces.</p>

                            </div>

                        </div>
                        <div className='item_container row'>
                            <div className='col-2 d-flex align-items-center  justify-content-end'>
                                <img src={Flight2} alt="" />
                            </div>
                            <div className='col-8 mt-3'>
                                <h3>Discovery call</h3>
                                <p>Schedule a free consultation to understand your project goals.</p>

                            </div>

                        </div>
                        <div className='item_container row'>
                            <div className='col-2 d-flex align-items-center  justify-content-end'>
                                <img src={Flight3} alt="" />
                            </div>
                            <div className='col-8 mt-3'>
                                <h3>Agile development</h3>
                                <p>We employ a collaborative approach for efficient project execution.</p>

                            </div>

                        </div>

                    </div>

                </div>
            </div>) : null}
            {searchTerm == 'devops' ? (<div className='YourTrusted wow fadeInUp' data-wow-delay="0.5s">
                <div className='row'>
                    <div className='col-md-6 py-5'>

                        <img className='img-fluid' src={Devops1} alt="" />

                    </div>
                    <div className='col-md-6 py-5 Trustedpatner'>
                        <div>

                            <h2>Accelerate your digital journey with devOps consulting</h2>

                            <p className='desp'>Our expert guidance propels your business forward through seamless collaboration, automation, and continuous delivery. Experience faster time-to-market, improved software quality, and enhanced operational efficiency.</p>

                        </div>


                    </div>

                </div>
            </div>) : null}
            {searchTerm == 'Ui-Ux-design' ? (<div className='YourTrusted wow fadeInUp' data-wow-delay="0.5s">
                <div className='row'>
                    <div className='col-md-6 py-5'>

                        <img className='img-fluid' src={UiUx3} alt="" />

                    </div>
                    <div className='col-md-6 py-5 Trustedpatner'>
                        <div>

                            <h2>Your Vision, Our Design Expertise</h2>

                            <p className='desp'>Vikgol is a leading UI/UX design agency in Bangalore, dedicated to creating intuitive and visually stunning digital products. Our team of experts combines strategic thinking, user research, and design innovation to deliver exceptional user experiences.

                            </p>
                            <p className='desp'>

                                We understand that a great user experience is the cornerstone of successful digital products. Our data-driven approach ensures that every design decision is backed by insights and user feedback. Let's create something extraordinary together.</p>

                        </div>


                    </div>

                </div>
            </div>) : null}
            {searchTerm == 'digital_marketing' ? (<div className='YourTrusted wow fadeInUp' data-wow-delay="0.5s">
                <div className='row'>
                    <div className='col-md-6 py-5'>

                        <img className='img-fluid' src={Dm3} alt="" />

                    </div>
                    <div className='col-md-6 py-5 Trustedpatner'>
                        <div className='py-lg-5'>

                            <h2>Elevate your digital presence with comprehensive marketing solutions</h2>

                            <p className='desp'>We are your strategic partners in achieving online success. Our integrated approach combines SEO, SMM, content marketing, PPC, and ASO to boost your brand visibility, engage your audience, and drive tangible results. From crafting compelling content to optimizing your online campaigns, we empower businesses to thrive in the digital landscape.

                            </p>
                          

                        </div>


                    </div>

                </div>
            </div>) : null}
            {searchTerm == 'software_testing' ? (<div className='YourTrusted wow fadeInUp' data-wow-delay="0.5s">
                <div className='row'>
                    <div className='col-md-6 py-5'>

                        <img className='img-fluid' src={Sw3} alt="" />

                    </div>
                    <div className='col-md-6 py-5 Trustedpatner'>
                        <div className='py-lg-5'>

                            <h2>Our test automation services</h2>

                            <p className='desp'>At Vikgol, our Test Automation Services deliver a powerful combination of quality and speed for unparalleled efficiency. We enhance your productivity by automating repetitive tasks, reducing manual errors, and accelerating your time-to-market. Utilizing industry-leading tools and advanced techniques, we ensure thorough testing and high-quality outcomes, giving you a competitive edge. Experience how precision meets excellence with our streamlined automation solutions.

                            </p>
                          

                        </div>


                    </div>

                </div>
            </div>) : null}
            {searchTerm == 'Gen_AI' ? (<div className='YourTrusted wow fadeInUp' data-wow-delay="0.5s">
                <div className='row'>
                    <div className='col-md-6 py-5'>

                        <img className='img-fluid' src={Gen3} alt="" />

                    </div>
                    <div className='col-md-6 py-5 Trustedpatner'>
                        <div className='py-lg-5'>

                            <h2>How Vikgol's AI-powered chatbots can transform your business</h2>

                            <p className='desp'>Elevate your business with our cutting-edge custom chatbots. Experience enhanced customer satisfaction, increased efficiency, cost savings, valuable data insights, personalised interactions, lead generation, consistent branding, scalability, and a competitive advantage. Our AI-powered chatbots are designed to transform your customer experience and drive business growth.

                            </p>
                          

                        </div>


                    </div>

                </div>
            </div>) : null}



        </>

    )
}

export default YourTrusted