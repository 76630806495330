import React from 'react'

function CareerBanner() {
  return (
    <div className='ContactPageBanner careerbanner wow fadeInUp' data-wow-delay="1s" >
    <div className='col-12 InsideBanner justify-content-center'>

        <p className='text-center d-block wow fadeInUp'  data-wow-delay="1.7s">
        Build your career with us
        </p>

    </div>
</div>
  )
}

export default CareerBanner
