import React from 'react'
import './privacy.scss';

function Privacy() {
  return (
    <div className="privacy-policy-container wow fadeInUp" data-wow-delay ="0.5s">
        <h2>Privacy Policy</h2>

   
      <section className="section">
      <h3>1. What Personal Information Do We Collect?</h3>
        <p>
          When ordering or registering on our site, you may be asked to provide details such as your name, email address, mailing address, phone number, or other relevant information to enhance your experience.
        </p>
        <h3>2. When Do We Collect Information?</h3>
        <p>
          We collect information when you register on our site, place an order, subscribe to a newsletter, respond to a survey, fill out a form, or enter information on our site.
        </p>
        <h3>3. How Do We Use Your Information?</h3>
        <p>
          We may use the collected information to:
        </p>
        <ul>
          <li>Personalize your experience and deliver content and product offerings tailored to your interests.</li>
          <li>Improve our website to better serve you.</li>
          <li>Enhance customer service by responding to your requests more effectively.</li>
          <li>Follow up with you after correspondence via live chat, email, or phone.</li>
        </ul>
      </section>

      <section className="section">
      <h3>4. How Do We Protect Your Information?</h3>
        <ul>
          <li>Our site undergoes regular security scans for vulnerabilities and malware.</li>
          <li>Personal Information is stored behind secured networks and accessed only by authorized personnel.</li>
          <li>Sensitive information is encrypted using Secure Socket Layer (SSL) technology.</li>
          <li>We implement various security measures during order placement and information submission.</li>
          <li>Credit card information may be stored for up to 60 days to expedite future orders and automate billing.</li>
        </ul>
      </section>

      <section className="section">
      <h3>5. Do We Use 'Cookies'?</h3>
        <p>
          Yes, cookies are small files transferred to your computer's hard drive through your web browser. They help us:
        </p>
        <ul>
          <li>Remember and process items in your shopping cart.</li>
          <li>Understand your preferences based on site activity.</li>
          <li>Compile aggregate data about site traffic and interactions.</li>
          <li>You can manage cookies through your browser settings. Disabling cookies may affect site functionality.</li>
        </ul>
      </section>

      <section className="section">
      <h3>6. Third-Party Disclosure & Link</h3>
        <ul>
          <li>We do not sell, trade, or transfer your Personally Identifiable Information to outside parties.</li>
          <li>We do not include or offer third-party products or services on our website.</li>
        </ul>
      </section>

      <section className="section">
      <h3>7. Fair Information Practices</h3>
        <ul>
          <li>We adhere to Fair Information Practices Principles, which are integral to global data protection laws.</li>
          <li>In case of a data breach, we will notify you via email within 7 business days.</li>
          <li>Individuals have the right to pursue legal recourse against non-compliant data collectors and processors.</li>
        </ul>
      </section>

      <section className="section">
      <h3>8. CAN-SPAM Act</h3>
        <ul>
          <li>We collect email addresses to send information, respond to inquiries, process orders, and provide updates related to products and services.</li>
          <li>To comply with CAN-SPAM, we:</li>
          <ul>
            <li>Avoid using false or misleading subjects or email addresses.</li>
            <li>Honor unsubscribe requests promptly. To unsubscribe, email us at [your email address].</li>
          </ul>
        </ul>
      </section>
    </div>
  )
}

export default Privacy