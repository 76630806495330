import React from 'react'
import DetailBanner from './DetailBanner'
import LogosSlider from '../LogosSlider'
import ServiceImpact from './ServiceImpact'
import ServiceCompressive from './ServiceCompressive'
import FuelInvoation from '../home/FuelInvoation'
import YourTrusted from './YourTrusted'
import CompressiveAdd from './CompressiveAdd'
import HarnesingAI from './HarnesingAI'
import { useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

function ServiceDetail() {

  const query = useQuery();
  const searchTerm = query.get('ServiceType'); //

  return (
    <div className='container-fluid overflowHide px-0  mt-3 wow fadeInUp' data-wow-delay ="1s">
        <DetailBanner/>
        <LogosSlider/>
        <ServiceImpact/>
        <YourTrusted/>
        <ServiceCompressive/>
        <CompressiveAdd/>
        <HarnesingAI/>
        <FuelInvoation/>


    </div>
  )
}

export default ServiceDetail