import React from 'react';
import { fuel1, fuel10, fuel11, fuel12, fuel13, fuel14, fuel15, fuel16, fuel17, fuel18, fuel19, fuel2, fuel20, fuel21, fuel22, fuel23, fuel24, fuel25, fuel26, fuel27, fuel3, fuel4, fuel5, fuel6, fuel7, fuel8, fuel9 } from '../../assets';
import TechCarousel from './TechCarousel';


const techStack = [
  { src: fuel1, alt: 'Angular' },
  { src: fuel2, alt: 'Angular' },
  { src: fuel3, alt: 'Angular' },
  { src: fuel4, alt: 'Angular' },
  { src: fuel5, alt: 'Angular' },
  { src: fuel6, alt: 'Angular' },
  { src: fuel7, alt: 'Angular' },
  { src: fuel8, alt: 'Angular' },
  { src: fuel9, alt: 'Angular' },
  { src: fuel10, alt: 'Angular' },
  { src: fuel11, alt: 'Angular' },
  { src: fuel12, alt: 'Angular' },
  { src: fuel13, alt: 'Angular' },
  { src: fuel14, alt: 'Angular' },
  { src: fuel15, alt: 'Angular' },
  { src: fuel16, alt: 'Angular' },
  { src: fuel17, alt: 'Angular' },
  { src: fuel18, alt: 'Angular' },
  { src: fuel19, alt: 'Angular' },
  { src: fuel20, alt: 'Angular' },
  { src: fuel21, alt: 'Angular' },
  { src: fuel22, alt: 'Angular' },
  { src: fuel23, alt: 'Angular' },
  { src: fuel24, alt: 'Angular' },
  { src: fuel25, alt: 'Angular' },
  { src: fuel26, alt: 'Angular' },
  { src: fuel27, alt: 'Angular' },
  
  

];

const FuelInvoation = () => {
  return (
    <div className="tech-stack-container wow fadeInUp" data-wow-delay ="0.5s">
      <div className="col-12 text-center  py-5">
        <div className='w-75 Mobile_width m-auto'>
        <h2 className="font-weight-bold Fuel-head">Fuel your innovation with our tech stack</h2>
        <p className="lead">Discover a vast array of cutting-edge technologies to bring your vision to life. We’ve curated a diverse toolkit to tackle any challenge and deliver exceptional results.</p>

        </div>
       
        <div className='col-12'>
        <TechCarousel techStack={techStack} direction="right" />
        <TechCarousel techStack={techStack} direction="left" />
        </div>

      </div>
    </div>
  );
}

export default FuelInvoation;