import React from 'react'
import { myteam1, myteam2, myteam3, myteam4, myteam5, teamic1, teamic2, teamic3 } from '../../assets'

function MyTeam() {
    return (
        <div className='MyTeam'>
            <h2>Our core team members</h2>

            <div className='row d-flex mt-lg-5 justify-content-center'>

                <div className='col-md-4'>
                    <div className='DesignLayout'>
                        <div className='img-container'>
                            <img src={myteam2} alt="" className='img-person img-fluid' />
                        </div>

                        <div className='text-details'>
                            <h3>Naveen kumar </h3>
                            <p className='det1'>Director, IIT-Bombay <span className='float-right'>
                            {/* <a href='https://www.linkedin.com/in/naveenkr59/' target='_blank'><img src={teamic1} alt="" /></a>   */}
                            </span></p>
                            <p className='det2'>Naveen Kumar, a dynamic leader at Vikgol with a B.Tech degree from IIT Bombay, drives success through his technical expertise, innovation, and forward-thinking approach.</p>
                        </div>
                    </div>

                </div>
                <div className='col-md-4'>
                    <div className='DesignLayout'>
                        <div className='img-container'>
                            <img src={myteam1} alt="" className='img-person img-372 img-fluid' />
                        </div>

                        <div className='text-details'>
                            <h3>Harsh Vardhan Singh</h3>
                            <p className='det1'>Founder and CEO <span className='float-right'>
                            {/* <a href='https://www.linkedin.com/in/harsh-vardhan-singh-dscet/' target='_blank'><img src={teamic1} alt="" /></a>   */}
                            </span></p>
                            <p className='det2'>A visionary leader and the Founder of Vikgol, where he combines his technical expertise with a passion for innovation. With a B.Tech degree, Harsh has always seen programming and DevOps as an art form, where each line of code reflects creativity and precision.</p>
                        </div>
                    </div>

                </div>

            </div>
            <div className='row d-flex mt-4 justify-content-center'>

                <div className='col-md-4'>
                    <div className='DesignLayout'>
                        <div className='img-container2'>
                            <img src={myteam3} alt="" className='img-person img-fluid' />
                        </div>

                        <div className='text-details'>
                            <h3>Sweta Raha</h3>
                            <p >Building Vikgol  </p>
                         
                        </div>
                    </div>

                </div>
                <div className='col-md-4'>
                    <div className='DesignLayout'>
                        <div className='img-container2'>
                            <img src={myteam4} alt="" className='img-person img-fluid' />
                        </div>

                        <div className='text-details'>
                            <h3>Nisha Kumari Rana</h3>
                            <p >Building Vikgol  </p>
                         
                        </div>
                    </div>

                </div>
                <div className='col-md-4'>
                    <div className='DesignLayout'>
                        <div className='img-container2'>
                            <img src={myteam5} alt="" className='img-person img-372 img-fluid' />
                        </div>

                        <div className='text-details'>
                            <h3>Rupesh Mahajan</h3>
                            <p >Building Vikgol  </p>
                         
                        </div>
                    </div>

                </div>
                

            </div>
        </div>
    )
}

export default MyTeam