import React, { useState } from 'react'
import { Breifcase, CRin1, CRin2, Clipboard, location, next } from '../../assets'
import ApplicationForm from './ApplyForm';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}


function CareerInfo() {
    const navigate = useNavigate();
    const query = useQuery();
    const searchTerm = query.get('jobType');

    const handleNavigate = (url) => {
        navigate(url);
    };

    return (
        <>

            {searchTerm === 'Nodejs' ? (<div className='CareerInfo wow fadeInUp' data-wow-delay="0.5s">

                <h2 > <img src={CRin2} alt="" onClick={() => { handleNavigate('/careers') }} /> | Node Js Developer

                </h2>

                <h3><img src={location} alt="" /> Bangalore, India &nbsp;&nbsp; &nbsp;&nbsp;  <img src={Breifcase} alt="" /> Full-Time  &nbsp;&nbsp;  &nbsp;&nbsp;<img src={Clipboard} alt="" /> 01 Vacancy</h3>
                <div className='row d-flex align-items-center'>
                    <div className='col-md-9'>
                        <h1>Node Js Developer</h1>
                    </div>
                    <div className='col-md-3'>
                        <a href="#" className="btn btn-primary btn-visonimp mt-3  btn-lg" data-bs-toggle="modal"
                            data-bs-target="#exampleModal"><span>Apply now</span> <img className='mt-1 imgNext' src={next} alt="" /></a>
                    </div>


                </div>

            </div>) : null}
            {searchTerm === 'Flutter' ? (<div className='CareerInfo wow fadeInUp' data-wow-delay="0.5s">

                <h2 > <img src={CRin2} alt="" onClick={() => { handleNavigate('/careers') }} /> | Career / Flutter Developer

                </h2>

                <h3><img src={location} alt="" /> Bangalore, India &nbsp;&nbsp; &nbsp;&nbsp;  <img src={Breifcase} alt="" /> Full-Time  &nbsp;&nbsp;  &nbsp;&nbsp;<img src={Clipboard} alt="" /> 01 Vacancy</h3>
                <div className='row d-flex align-items-center'>
                    <div className='col-md-9'>
                        <h1>Flutter Developer</h1>
                    </div>
                    <div className='col-md-3'>
                        <a href="#" className="btn btn-primary btn-visonimp mt-3  btn-lg" data-bs-toggle="modal"
                            data-bs-target="#exampleModal"><span>Apply now</span> <img className='mt-1 imgNext' src={next} alt="" /></a>
                    </div>


                </div>

            </div>) : null}
            {searchTerm === 'python' ? (<div className='CareerInfo wow fadeInUp' data-wow-delay="0.5s">

                <h2 > <img src={CRin2} alt="" onClick={() => { handleNavigate('/careers') }} /> | Career / Python Developer

                </h2>

                <h3><img src={location} alt="" /> Bangalore, India &nbsp;&nbsp; &nbsp;&nbsp;  <img src={Breifcase} alt="" /> Full-Time  &nbsp;&nbsp;  &nbsp;&nbsp;<img src={Clipboard} alt="" /> 01 Vacancy</h3>
                <div className='row d-flex align-items-center'>
                    <div className='col-md-9'>
                        <h1>Python Developer</h1>
                    </div>
                    <div className='col-md-3'>
                        <a href="#" className="btn btn-primary btn-visonimp mt-3  btn-lg" data-bs-toggle="modal"
                            data-bs-target="#exampleModal"><span>Apply now</span> <img className='mt-1 imgNext' src={next} alt="" /></a>
                    </div>


                </div>

            </div>) : null}
            {searchTerm === 'DevOps' ? (<div className='CareerInfo wow fadeInUp' data-wow-delay="0.5s">

                <h2 > <img src={CRin2} alt="" onClick={() => { handleNavigate('/careers') }} /> | Career / DevOps

                </h2>

                <h3><img src={location} alt="" /> Bangalore, India &nbsp;&nbsp; &nbsp;&nbsp;  <img src={Breifcase} alt="" /> Full-Time  &nbsp;&nbsp;  &nbsp;&nbsp;<img src={Clipboard} alt="" /> 01 Vacancy</h3>
                <div className='row d-flex align-items-center'>
                    <div className='col-md-9'>
                        <h1>DevOps</h1>
                    </div>
                    <div className='col-md-3'>
                        <a href="#" className="btn btn-primary btn-visonimp mt-3  btn-lg" data-bs-toggle="modal"
                            data-bs-target="#exampleModal"><span>Apply now</span> <img className='mt-1 imgNext' src={next} alt="" /></a>
                    </div>


                </div>

            </div>) : null}
            {searchTerm === 'react' ? (<div className='CareerInfo wow fadeInUp' data-wow-delay="0.5s">

                <h2 > <img src={CRin2} alt="" onClick={() => { handleNavigate('/careers') }} /> | Career / React js

                </h2>

                <h3><img src={location} alt="" /> Bangalore, India &nbsp;&nbsp; &nbsp;&nbsp;  <img src={Breifcase} alt="" /> Full-Time  &nbsp;&nbsp;  &nbsp;&nbsp;<img src={Clipboard} alt="" /> 01 Vacancy</h3>
                <div className='row d-flex align-items-center'>
                    <div className='col-md-9'>
                        <h1>React js Developer</h1>
                    </div>
                    <div className='col-md-3'>
                        <a href="#" className="btn btn-primary btn-visonimp mt-3  btn-lg" data-bs-toggle="modal"
                            data-bs-target="#exampleModal"><span>Apply now</span> <img className='mt-1 imgNext' src={next} alt="" /></a>
                    </div>


                </div>

            </div>) : null}
            {searchTerm === 'angular' ? (<div className='CareerInfo wow fadeInUp' data-wow-delay="0.5s">

                <h2 > <img src={CRin2} alt="" onClick={() => { handleNavigate('/careers') }} /> | Career / Angular Developer

                </h2>

                <h3><img src={location} alt="" /> Bangalore, India &nbsp;&nbsp; &nbsp;&nbsp;  <img src={Breifcase} alt="" /> Full-Time  &nbsp;&nbsp;  &nbsp;&nbsp;<img src={Clipboard} alt="" /> 01 Vacancy</h3>
                <div className='row d-flex align-items-center'>
                    <div className='col-md-9'>
                        <h1>Angular Developer</h1>
                    </div>
                    <div className='col-md-3'>
                        <a href="#" className="btn btn-primary btn-visonimp mt-3  btn-lg" data-bs-toggle="modal"
                            data-bs-target="#exampleModal"><span>Apply now</span> <img className='mt-1 imgNext' src={next} alt="" /></a>
                    </div>


                </div>

            </div>) : null}

            <ApplicationForm />
        </>
    )
}

export default CareerInfo