import React from 'react'


function BannerServices() {
  return (
    <div className='ServiceBanner mx-3 wow fadeInUp ' data-wow-delay ="1s"  >

        <div className='col-12 InsideBanner justify-content-center'>

            <p className='wow fadeInUp' data-wow-delay ="1.7s">
            Empowering Your Digital Transformation with Innovative Solution
            </p>

        </div>
    </div>
  )
}

export default BannerServices