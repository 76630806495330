import React from 'react'
import { VisonPact, next } from '../../assets'

function VisonAndImapact() {
  return (
    <div className='VisonImpBg'>

<div className="container VisonImpact py-lg-5 wow fadeInUp" data-wow-delay ="0.5s">
    <div className="row align-items-center">
      <div className="col-md-6">
        <h1 className="font-weight-bold">From Vision to Impact: The Vikgol Journey</h1>
        <p className="lead">
          <a href="#" className="text-primary ">Vikgol</a> ignited in 2018, fuelled by a relentless pursuit of digital innovation.
          We’ve transformed challenges into graphics, creating digital symphonies that redefine industries. With expertise
          in [list of core services, e.g., web development, mobile app development, AI, cloud solutions], we deliver
          cutting-edge solutions tailored to your business goals. Join us in shaping the future.
        </p>
        <a href="https://calendly.com/harsh-46/30min?month=2024-08" target='_blank' className="btn btn-primary btn-visonimp  btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={next} alt="" /></a>
      </div>
      <div className="col-md-6 pt-lg-5 d-flex justify-content-center">
        <img src={VisonPact} alt="Vikgol Journey" className="img-fluid vigole_img mt-5"/>
      </div>
    </div>
  </div>

    </div>
   
  
)
}

export default VisonAndImapact