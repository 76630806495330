import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faCloud, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { TransIc1, TransIc2, TransIc3, TransIc4, TransIc5, TransIc6, Transnew1, Transnew2, Transnew3 } from '../../assets';

const SingleComponentTrans = () => {


    return (
        <div className='TransformingIdeas  TransIdeaMid py-5 w-100 wow fadeInUp ' data-wow-delay="0.5s" >
            <h1 className="headerT">Transforming ideas into reality</h1>

            <p className="SmallSub mt-2"> Empowering enterprises and fast-growing organisations to thrive in today's digital landscape with our industry-specific expertise.</p>
            <div className="containerTransMid justify-content-start row mt-lg-5">
                <div className="paneltd col-md-4 px-3">

                    <div className='w-100 WrapContainerTrans p-1'>
                        <div className='Img-container '>
                            <div className='Img_hover'>
                                <img src={Transnew1} alt="" />
                            </div>

                        </div>
                        <div className='w-100 px-3'>
                            <h2 className='tittletd'>Generative AI</h2>
                            <p className="descriptiontd">Empowering your digital presence with bespoke web applications tailored to your unique business needs and goals.</p>
                        </div>

                    </div>
                </div>


                <div className="paneltd col-md-4  px-3">
                    <div className='w-100 WrapContainerTrans p-1'>
                        <div className='Img-container '>


                            <div className='Img_hover'>
                                <img src={TransIc1} alt="" />
                            </div>

                        </div>
                        <div className='w-100 px-3'>

                            <h2 className='tittletd'>Web App Development</h2>
                            <p className="descriptiontd">Seamlessly transition your IT infrastructure and applications to the cloud like AWS, Azure, GCP for improved scalability, flexibility, and cost-efficiency.</p>

                        </div>

                    </div>

                </div>
                <div className="paneltd col-md-4  px-2">
                    <div className='w-100 WrapContainerTrans p-1'>
                        <div className='Img-container '>
                            <div className='Img_hover'>
                                <img src={TransIc2} alt="" />
                            </div>

                        </div>
                        <div className='w-100 px-3'>
                            <h2 className='tittletd'>Mobile App Development</h2>
                            <p className="descriptiontd">Transform and Mobilize Your Business Anywhere, Anytime with Custom Mobile App Development Services from Vikgol.</p>
                        </div>

                    </div>

                </div>
                <div className="paneltd col-md-4 px-3">

                    <div className='w-100 WrapContainerTrans p-1'>
                        <div className='Img-container '>
                            <div className='Img_hover'>
                                <img src={Transnew2} alt="" />
                            </div>

                        </div>
                        <div className='w-100 px-3'>
                            <h2 className='tittletd'>UI/UX</h2>
                            <p className="descriptiontd">Designing intuitive and engaging interfaces that enhance user experience and align with business goals.</p>
                        </div>

                    </div>
                </div>
                <div className="paneltd col-md-4 px-3">

                    <div className='w-100 WrapContainerTrans p-1'>
                        <div className='Img-container '>
                            <div className='Img_hover'>
                                <img src={Transnew3} alt="" />
                            </div>

                        </div>
                        <div className='w-100 px-3'>
                            <h2 className='tittletd'>DevOps</h2>
                            <p className="descriptiontd">
                                Automating and optimizing infrastructure with DevOps to streamline deployments, enhance scalability, and ensure reliability.
                            </p>
                        </div>

                    </div>
                </div>
                <div className="paneltd col-md-4 px-3">

                    <div className='w-100 WrapContainerTrans p-1'>
                        <div className='Img-container '>
                            <div className='Img_hover'>
                                <img src={TransIc4} alt="" />
                            </div>

                        </div>
                        <div className='w-100 px-3'>
                            <h2 className='tittletd'>Quality Assurance</h2>
                            <p className="descriptiontd">Testing at every phase of SDLC, business analysis, manual, and automation testing.</p>
                        </div>

                    </div>
                </div>
                <div className="paneltd col-md-4 px-3">

                    <div className='w-100 WrapContainerTrans p-1'>
                        <div className='Img-container '>
                            <div className='Img_hover'>
                                <img src={TransIc5} alt="" />
                            </div>

                        </div>
                        <div className='w-100 px-3'>
                            <h2 className='tittletd'>Staff Augmentation</h2>
                            <p className="descriptiontd">Staff augmentation services offer flexible staffing solutions by providing skilled professionals to complement existing teams and meet project demands.</p>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default SingleComponentTrans;
