import React from 'react'
import { next } from '../../assets'

function AreUReady() {
    return (
        <div className='AreReayImg'>

<div className='AreUReady row align-items-center justify-content-center Py-5 '>
            <div>
                <p >
                    Are you ready to transform your ideas into reality?
                </p>


                <a target='_blank' href="https://calendly.com/harsh-46/30min?month=2024-08"  className="btn btn-primary btn-visonimp m-auto  btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={next} alt="" /></a>
            </div>


        </div>

        </div>
       
    )
}

export default AreUReady