import React from 'react'
import { Itemb1, Itemb2, Itemb3, Itemb4, Itemb5, Itemb6 } from '../../assets'
import { useNavigate } from 'react-router-dom';

function BlogItmes() {

    const navigate = useNavigate();

    const handleNavigate = (url) => {
        navigate(url);
    };
    return (
        <div className='blogItems wow fadeInUp' data-wow-delay="0.5s">

            <h2>Industry news & trends</h2>

            <p>Stay updated on the latest tech trends and industry insights. Discover how innovation is shaping the future.</p>

            <div className='row px-lg-4 mt-lg-5'>

                <div className='col-md-4' onClick={() => handleNavigate(`/blog/detail?BlogType=blog1`)}>
                    <div className='itemDesign'>

                        <div className='img-div' style={{
                            backgroundImage: `url(${Itemb1})`,

                        }}>

                            <div className='d-flex tagcontainer justify-content-around w-100 px-5'>

                                <button>Analyze</button>  <button>Marketing</button>


                            </div>

                        </div>

                        <div className='d-flex mt-2 px-2 borderBottom w-100 justify-content-between'>
                            <p>December 05, 2021</p>
                            <p>3 min read</p>

                        </div>
                        <div className='descriptionContainer mt-3 px-2'>
                            <h2> Setting Up Kubernetes with Kubespray</h2>
                            <p>Kubernetes automates container deployment and scaling; tools like Kubespray simplify setup and management complexities.</p>

                        </div>

                    </div>

                </div>
                <div className='col-md-4' onClick={() => handleNavigate(`/blog/detail?BlogType=blog2`)}>
                    <div className='itemDesign'>

                        <div className='img-div' style={{
                            backgroundImage: `url(${Itemb2})`,

                        }}>

                            <div className='d-flex tagcontainer justify-content-around w-100 px-5'>

                                <button>Analyze</button>  <button>Marketing</button>


                            </div>

                        </div>

                        <div className='d-flex mt-2 px-2 borderBottom w-100 justify-content-between'>
                            <p>December 05, 2021</p>
                            <p>3 min read</p>

                        </div>
                        <div className='descriptionContainer mt-3 px-2'>
                            <h2>Creating a Cost-Efficient Jenkins Setup </h2>

                            <p>Jenkins simplifies CI/CD but is costly. AWS Spot Instances reduce costs. This guide sets up Jenkins cost-efficiently.</p>

                        </div>

                    </div>

                </div>
                <div className='col-md-4' onClick={() => handleNavigate(`/blog/detail?BlogType=blog3`)}>
                    <div className='itemDesign'>

                        <div className='img-div' style={{
                            backgroundImage: `url(${Itemb3})`,

                        }}>

                            <div className='d-flex tagcontainer justify-content-around w-100 px-5'>

                                <button>Analyze</button>  <button>Marketing</button>


                            </div>

                        </div>

                        <div className='d-flex mt-2 px-2 borderBottom w-100 justify-content-between'>
                            <p>December 05, 2021</p>
                            <p>3 min read</p>

                        </div>
                        <div className='descriptionContainer mt-3 px-2'>
                            <h2>Achieving High Availability on AWS </h2>
                            <p>Ensure high availability with AWS and Terraform. This guide shows how to build resilient, scalable infrastructure efficiently.</p>

                        </div>

                    </div>

                </div>



            </div>
           


        </div>
    )
}

export default BlogItmes
