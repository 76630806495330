import React from 'react'
import { Devopscm1, Devopscm2, Devopscm3, Devopscm4, Devopscm5, Devopscm6, Dm4, Dm5, Dm6, Dm7, Dm8, Dm9, Gen4, Gen5, Gen6, Gen7, Mobilec1, Mobilec2, Mobilec3, SerC1, SerC2, SerC3, SerC4, SerC5, SerC6, Sw4, Sw5, Sw6, TransIc1, TransIc10, TransIc11, TransIc12, TransIc2, TransIc3, TransIc4, TransIc5, TransIc6, TransIc7, TransIc8, TransIc9, UiUx4, UiUx5, UiUx6, UiUx7, UiUx8, UiUx9, next } from '../../assets';
import { useLocation } from 'react-router-dom';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ServiceCompressive() {

    const query = useQuery();
    const searchTerm = query.get('ServiceType');

    return (
        <>
            {searchTerm == 'web_app_development' ? (<div className='TransformingIdeas TransIdeaMid py-5 w-100 wow fadeInUp ' data-wow-delay="0.5s" >
                <h1 className="headerT headerTBt d-block">Comprehensive web development services</h1>


                <div className="containerTransMid row mt-lg-5">
                    <div className="paneltd col-md-4 px-3">

                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={SerC1} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>CMS Website Development</h2>
                                <p className="descriptiontd">Build dynamic, scalable websites with Drupal, Joomla, or Magento. Our experts craft tailored solutions to meet your business goals.</p>
                            </div>

                        </div>
                    </div>


                    <div className="paneltd col-md-4  px-3">
                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>


                                <div className='Img_hover'>
                                    <img src={SerC2} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>

                                <h2 className='tittletd'>WordPress Development</h2>
                                <p className="descriptiontd">From blogs to e-commerce, we create stunning WordPress websites that drive results. Enjoy flexibility and ease of management.</p>

                            </div>

                        </div>

                    </div>
                    <div className="paneltd col-md-4  px-2">
                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={SerC3} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>Shopify Website Development</h2>
                                <p className="descriptiontd">Launch your online store with confidence. Our Shopify experts build high-converting websites for your business.</p>
                            </div>

                        </div>

                    </div>
                    <div className="paneltd col-md-4 px-3">

                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={SerC4} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>PHP Web Development</h2>
                                <p className="descriptiontd">Robust and secure web applications tailored to your needs. Our PHP developers deliver custom solutions.</p>
                            </div>

                        </div>
                    </div>
                    <div className="paneltd col-md-4 px-3">

                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={SerC5} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>Angular Website Development</h2>
                                <p className="descriptiontd">Create interactive and engaging web experiences with Angular. We build fast, responsive, and scalable applications.
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="paneltd col-md-4 px-3">

                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={SerC6} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>React Website Development</h2>
                                <p className="descriptiontd">Beyond development, we stand by your side with continuous updates, maintenance, and assistance, ensuring your software remains at its best long after deployment.</p>
                            </div>

                        </div>
                    </div>

                    <div className='col-md-12  px-4 '>
                        <div className='row  NewDesDiv py-5'>

                            <div className='col-md-9 px-5 designAre'>
                                <h5>Are you ready to transform your ideas into reality?</h5>
                                <p>Develop user-centric web applications with React. Our expertise delivers high-performance and efficient solutions.</p>

                            </div>

                            <div className='col-md-3 btn-design d-flex justify-content-center'>
                                <a href='https://calendly.com/harsh-46/30min?month=2024-08' target='_blank'>
                                    <button type='submit' className="btn btn-primary btn-visonimpnew  btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={next} alt="" /></button>
                                </a>

                            </div>

                        </div>

                    </div>
                </div>



            </div>) : null}
            {searchTerm == 'mobile_app_development' ? (<>
                <div className='TransformingIdeas TransIdeaMid py-5 w-100 wow fadeInUp ' data-wow-delay="0.5s" >
                    <h1 className="headerT headerTBt d-block">Comprehensive Mobile App Development Services</h1>


                    <div className="containerTransMid row mt-lg-5">
                        <div className="paneltd col-md-4 px-3">

                            <div className='w-100 WrapContainerTrans p-1'>
                                <div className='Img-container '>
                                    <div className='Img_hover'>
                                        <img src={Mobilec1} alt="" />
                                    </div>

                                </div>
                                <div className='w-100 px-3'>
                                    <h2 className='tittletd'>Android app development excellence</h2>
                                    <p className="descriptiontd">Our expert Android developers create stunning apps tailored to various screen sizes and the latest Android OS features.</p>
                                </div>

                            </div>
                        </div>


                        <div className="paneltd col-md-4  px-3">
                            <div className='w-100 WrapContainerTrans p-1'>
                                <div className='Img-container '>


                                    <div className='Img_hover'>
                                        <img src={Mobilec2} alt="" />
                                    </div>

                                </div>
                                <div className='w-100 px-3'>

                                    <h2 className='tittletd'>iOS App Development Mastery</h2>
                                    <p className="descriptiontd">Deliver exceptional iOS apps with our cutting-edge development expertise. We stay ahead of the curve to create user-centric experiences.</p>

                                </div>

                            </div>

                        </div>
                        <div className="paneltd col-md-4  px-2">
                            <div className='w-100 WrapContainerTrans p-1'>
                                <div className='Img-container '>
                                    <div className='Img_hover'>
                                        <img src={Mobilec3} alt="" />
                                    </div>

                                </div>
                                <div className='w-100 px-3'>
                                    <h2 className='tittletd'>Cross-Platform App Development</h2>
                                    <p className="descriptiontd">Reach a wider audience with one powerful app. Our cross-platform expertise delivers high-performance solutions across devices.</p>
                                </div>

                            </div>

                        </div>


                        <div className='col-md-12  px-4 '>
                            <div className='row  NewDesDiv py-5'>

                                <div className='col-md-9 px-5 designAre'>
                                    <h5>Are you ready to transform your ideas into reality?</h5>
                                    <p>Develop user-centric web applications with React. Our expertise delivers high-performance and efficient solutions.</p>

                                </div>

                                <div className='col-md-3 btn-design d-flex justify-content-center'>
                                    <a href='https://calendly.com/harsh-46/30min?month=2024-08' target='_blank'>
                                        <button type='submit' className="btn btn-primary btn-visonimpnew  btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={next} alt="" /></button>
                                    </a>

                                </div>

                            </div>

                        </div>
                    </div>



                </div>
                <div className='OurFocusServices'>

                    <h2>Our focus: user-centric innovation</h2>

                    <div className='row mt-3'>
                        <div className='col-md-4'>
                            <div className='designLayout'>
                                <h3>CX is Our Priority</h3>
                                <p>Deliver exceptional user experiences that build lasting relationships.</p>


                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='designLayout'>
                                <h3>Data-driven excellence</h3>
                                <p>Leverage data insights to optimise app performance and user satisfaction.</p>


                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='designLayout'>
                                <h3>Cloud-Native Development</h3>
                                <p>Build scalable and reliable apps on robust cloud infrastructure.</p>


                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='designLayout'>
                                <h3>Agile Development </h3>
                                <p>Embrace flexibility and adaptability for rapid development and iteration.</p>


                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='designLayout'>
                                <h3>ASO Optimisation</h3>
                                <p>Enhance app visibility and discoverability through strategic ASO.</p>


                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='designLayout'>
                                <h3>Iterative Improvement</h3>
                                <p>Continuously refine and enhance your app based on user feedback.</p>


                            </div>

                        </div>

                    </div>


                </div>
            </>
            ) : null}
            {searchTerm == 'devops' ? (<div className='TransformingIdeas TransIdeaMid py-5 w-100 wow fadeInUp ' data-wow-delay="0.5s" >
                <h1 className="headerT headerTBt d-block">Comprehensive web development services</h1>


                <div className="containerTransMid row mt-lg-5">
                    <div className="paneltd col-md-4 px-3">

                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={Devopscm1} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>Agile & DevOps Consulting Services</h2>
                                <p className="descriptiontd">Our expert Android developers create stunning apps tailored to various screen sizes and the latest Android OS features.</p>
                            </div>

                        </div>
                    </div>


                    <div className="paneltd col-md-4  px-3">
                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>


                                <div className='Img_hover'>
                                    <img src={Devopscm2} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>

                                <h2 className='tittletd'>Cloud and Automation Excellence</h2>
                                <p className="descriptiontd">Deliver exceptional iOS apps with our cutting-edge development expertise. We stay ahead of the curve to create user-centric experiences.</p>

                            </div>

                        </div>

                    </div>
                    <div className="paneltd col-md-4  px-2">
                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={Devopscm3} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>CI/CD Pipeline Mastery</h2>
                                <p className="descriptiontd">Reach a wider audience with one powerful app. Our cross-platform expertise delivers high-performance solutions across devices.</p>
                            </div>

                        </div>

                    </div>
                    <div className="paneltd col-md-4 px-3">

                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={Devopscm4} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>DevOps Transformation</h2>
                                <p className="descriptiontd">Our expert Android developers create stunning apps tailored to various screen sizes and the latest Android OS features.</p>
                            </div>

                        </div>
                    </div>
                    <div className="paneltd col-md-4 px-3">

                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={Devopscm5} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>Agile Project Management Expertise</h2>
                                <p className="descriptiontd">Deliver exceptional iOS apps with our cutting-edge development expertise. We stay ahead of the curve to create user-centric experiences.
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="paneltd col-md-4 px-3">

                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={Devopscm6} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>Driving Continuous Improvement</h2>
                                <p className="descriptiontd">Reach a wider audience with one powerful app. Our cross-platform expertise delivers high-performance solutions across devices.</p>
                            </div>

                        </div>
                    </div>

                    <div className='col-md-12  px-4 '>
                        <div className='row  NewDesDiv py-5'>

                            <div className='col-md-9 px-5 designAre'>
                                <h5>Are you ready to transform your ideas into reality?</h5>
                                <p>Develop user-centric web applications with React. Our expertise delivers high-performance and efficient solutions.</p>

                            </div>

                            <div className='col-md-3 btn-design d-flex justify-content-center'>
                                <a href='https://calendly.com/harsh-46/30min?month=2024-08' target='_blank'>
                                    <button type='submit' className="btn btn-primary btn-visonimpnew  btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={next} alt="" /></button>
                                </a>

                            </div>

                        </div>

                    </div>
                </div>



            </div>) : null}
            {searchTerm == 'Ui-Ux-design' ? (<div className='TransformingIdeas TransIdeaMid py-5 w-100 wow fadeInUp ' data-wow-delay="0.5s" >
                <h1 className="headerT headerTBt d-block">Transforming digital experiences with our user-focused UI/UX design solutions.</h1>


                <div className="containerTransMid row mt-lg-5">
                    <div className="paneltd col-md-4 px-3">

                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={UiUx4} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>User Research</h2>
                                <p className="descriptiontd">Unlock deep insights into user behaviour, preferences, and pain points with our thorough user research techniques.</p>
                            </div>

                        </div>
                    </div>


                    <div className="paneltd col-md-4  px-3">
                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>


                                <div className='Img_hover'>
                                    <img src={UiUx5} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>

                                <h2 className='tittletd'>Strategy</h2>
                                <p className="descriptiontd">Create a strategic blueprint for your digital projects that aligns user experience design with your business objectives. </p>

                            </div>

                        </div>

                    </div>
                    <div className="paneltd col-md-4  px-2">
                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={UiUx6} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>Branding</h2>
                                <p className="descriptiontd">Build a robust and cohesive brand identity that connects with your audience across all digital platforms.</p>
                            </div>

                        </div>

                    </div>
                    <div className="paneltd col-md-4 px-3">

                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={UiUx7} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>Content</h2>
                                <p className="descriptiontd">Develop engaging and user-centric content that captivates your audience and enhances their journey through your digital platforms. </p>
                            </div>

                        </div>
                    </div>
                    <div className="paneltd col-md-4 px-3">

                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={UiUx8} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>Experienced Design</h2>
                                <p className="descriptiontd">Leverage the skills of our expert UI/UX designers who blend aesthetic beauty with practical usability. </p>
                            </div>

                        </div>
                    </div>
                    <div className="paneltd col-md-4 px-3">

                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={UiUx9} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>UX Audit</h2>
                                <p className="descriptiontd">Discover opportunities for enhancement in your digital products with our detailed user experience audits. 
                                </p>
                            </div>

                        </div>
                    </div>


                    <div className='col-md-12  px-4 '>
                        <div className='row  NewDesDiv py-5'>

                            <div className='col-md-9 px-5 designAre'>
                                <h5>Are you ready to transform your ideas into reality?</h5>
                                <p>Develop user-centric web applications with React. Our expertise delivers high-performance and efficient solutions.</p>

                            </div>

                            <div className='col-md-3 btn-design d-flex justify-content-center'>
                                <a href='https://calendly.com/harsh-46/30min?month=2024-08' target='_blank'>
                                    <button type='submit' className="btn btn-primary btn-visonimpnew  btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={next} alt="" /></button>
                                </a>

                            </div>

                        </div>

                    </div>
                </div>



            </div>) : null}
            {searchTerm == 'digital_marketing' ? (<div className='TransformingIdeas TransIdeaMid py-5 w-100 wow fadeInUp ' data-wow-delay="0.5s" >
                <h1 className="headerT headerTBt d-block">Effective digital marketing services</h1>


                <div className="containerTransMid row mt-lg-5">
                    <div className="paneltd col-md-4 px-3">

                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={Dm4} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>Digital Marketing Strategies</h2>
                                <p className="descriptiontd">Designing impactful marketing strategies to elevate your brand's presence and engagement online.</p>
                            </div>

                        </div>
                    </div>


                    <div className="paneltd col-md-4  px-3">
                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>


                                <div className='Img_hover'>
                                    <img src={Dm5} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>

                                <h2 className='tittletd'>Search Engine Optimisation (SEO)</h2>
                                <p className="descriptiontd">Boost your website's rankings and organic traffic with expert SEO techniques for better visibility. </p>

                            </div>

                        </div>

                    </div>
                    <div className="paneltd col-md-4  px-2">
                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={Dm6} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>Social Media Marketing</h2>
                                <p className="descriptiontd">Connect with your audience on Facebook, Instagram, and LinkedIn through engaging content and targeted ads.</p>
                            </div>

                        </div>

                    </div>
                    <div className="paneltd col-md-4 px-3">

                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={Dm7} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>Content Marketing</h2>
                                <p className="descriptiontd">Develop compelling content like blog posts, articles, and videos to attract, engage, and convert your audience effectively. </p>
                            </div>

                        </div>
                    </div>
                    <div className="paneltd col-md-4 px-3">

                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={Dm8} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>Pay-Per-Click Advertising (PPC)</h2>
                                <p className="descriptiontd">Generate instant traffic with well-targeted PPC campaigns on Google and social media platforms for optimal ROI. </p>
                            </div>

                        </div>
                    </div>
                    <div className="paneltd col-md-4 px-3">

                        <div className='w-100 WrapContainerTrans p-1'>
                            <div className='Img-container '>
                                <div className='Img_hover'>
                                    <img src={Dm9} alt="" />
                                </div>

                            </div>
                            <div className='w-100 px-3'>
                                <h2 className='tittletd'>App Store Optimisation (ASO)</h2>
                                <p className="descriptiontd">Improve your mobile app's visibility and increase downloads with strategic optimisation in app stores.
                                </p>
                            </div>

                        </div>
                    </div>


                    <div className='col-md-12  px-4 '>
                        <div className='row  NewDesDiv py-5'>

                            <div className='col-md-9 px-5 designAre'>
                                <h5>Are you ready to transform your ideas into reality?</h5>
                                <p>Develop user-centric web applications with React. Our expertise delivers high-performance and efficient solutions.</p>

                            </div>

                            <div className='col-md-3 btn-design d-flex justify-content-center'>
                                <a href='https://calendly.com/harsh-46/30min?month=2024-08' target='_blank'>
                                    <button type='submit' className="btn btn-primary btn-visonimpnew  btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={next} alt="" /></button>
                                </a>

                            </div>

                        </div>

                    </div>
                </div>



            </div>) : null}
            {searchTerm == 'software_testing' ? (<>
                <div className='TransformingIdeas TransIdeaMid py-5 w-100 wow fadeInUp ' data-wow-delay="0.5s" >
                    <h1 className="headerT headerTBt d-block">Effective Software Testing services</h1>


                    <div className="containerTransMid row mt-lg-5">
                        <div className="paneltd col-md-4 px-3">

                            <div className='w-100 WrapContainerTrans p-1'>
                                <div className='Img-container '>
                                    <div className='Img_hover'>
                                        <img src={Sw4} alt="" />
                                    </div>

                                </div>
                                <div className='w-100 px-3'>
                                    <h2 className='tittletd'>QA Consulting</h2>
                                    <p className="descriptiontd">Get expert guidance and strategic recommendations to optimise your software testing processes</p>
                                </div>

                            </div>
                        </div>


                        <div className="paneltd col-md-4  px-3">
                            <div className='w-100 WrapContainerTrans p-1'>
                                <div className='Img-container '>


                                    <div className='Img_hover'>
                                        <img src={Sw5} alt="" />
                                    </div>

                                </div>
                                <div className='w-100 px-3'>

                                    <h2 className='tittletd'>QA as-a-Service</h2>
                                    <p className="descriptiontd">Boost your website's rankings and organic traffic with eEnjoy comprehensive testing services from planning to execution</p>

                                </div>

                            </div>

                        </div>
                        <div className="paneltd col-md-4  px-2">
                            <div className='w-100 WrapContainerTrans p-1'>
                                <div className='Img-container '>
                                    <div className='Img_hover'>
                                        <img src={Sw6} alt="" />
                                    </div>

                                </div>
                                <div className='w-100 px-3'>
                                    <h2 className='tittletd'>Test Automation</h2>
                                    <p className="descriptiontd">Our specialists design robust and scalable test automation frameworks tailored to your needs, utilising the latest tools</p>
                                </div>

                            </div>

                        </div>


                        <div className='col-md-12  px-4 '>
                            <div className='row  NewDesDiv py-5'>

                                <div className='col-md-9 px-5 designAre'>
                                    <h5>Are you ready to transform your ideas into reality?</h5>
                                    <p>Develop user-centric web applications with React. Our expertise delivers high-performance and efficient solutions.</p>

                                </div>

                                <div className='col-md-3 btn-design d-flex justify-content-center'>
                                    <a href='https://calendly.com/harsh-46/30min?month=2024-08' target='_blank'>
                                        <button type='submit' className="btn btn-primary btn-visonimpnew  btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={next} alt="" /></button>
                                    </a>

                                </div>

                            </div>

                        </div>
                    </div>



                </div>
                <div className='OurFocusServices'>

                    <h2>What sets us apart in QA & software testing</h2>

                    <div className='row mt-3'>
                        <div className='col-md-4'>
                            <div className='designLayout designLayout2'>
                                <h3>Expertise you can trust</h3>
                                <p>Our seasoned QA professionals are certified and experienced in the latest testing methodologies. We continuously update our skills to stay ahead of the curve.</p>


                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='designLayout designLayout2'>
                                <h3>Extensive testing solutions</h3>
                                <p>We offer a wide range of testing services, including functional, non-functional, automation, performance, and security testing, ensuring your software is robust and reliable.</p>


                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='designLayout designLayout2'>
                                <h3>Cutting-edge testing tools </h3>
                                <p>Using advanced tools like Selenium, Appium, Katalon Studio, and LoadRunner, we deliver precise and efficient testing.</p>


                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='designLayout designLayout2'>
                                <h3>Proven quality processes</h3>
                                <p>Our well-defined methodologies ensure consistent and repeatable results across all projects, regardless of complexity.</p>


                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='designLayout designLayout2'>
                                <h3>Flexible models</h3>
                                <p>We offer tailored engagement options, from dedicated teams to project-based services and staff augmentation, aligned with your business needs and budget.</p>


                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='designLayout designLayout2'>
                                <h3>Commitment to quality</h3>
                                <p>Quality is at the core of everything we do. We approach every project with a focus on delivering exceptional results and meeting the highest standards.</p>


                            </div>

                        </div>

                    </div>


                </div>
            </>
            ) : null}
             {searchTerm == 'Gen_AI' ? (<>
                <div className='TransformingIdeas TransIdeaMid py-5 w-100 wow fadeInUp ' data-wow-delay="0.5s" >
                    <h1 className="headerT headerTBt d-block">Our chatbot development services</h1>


                    <div className="containerTransMid d-flex justify-content-center row mt-lg-5">
                        <div className="paneltd col-md-4 px-3">

                            <div className='w-100 WrapContainerTrans p-1'>
                                <div className='Img-container '>
                                    <div className='Img_hover'>
                                        <img src={Gen4} alt="" />
                                    </div>

                                </div>
                                <div className='w-100 px-3'>
                                    <h2 className='tittletd'>Tailored chatbot development</h2>
                                    <p className="descriptiontd">We create chatbots that perfectly align with your brand and business objectives.</p>
                                </div>

                            </div>
                        </div>


                        <div className="paneltd col-md-4  px-3">
                            <div className='w-100 WrapContainerTrans p-1'>
                                <div className='Img-container '>


                                    <div className='Img_hover'>
                                        <img src={Gen5} alt="" />
                                    </div>

                                </div>
                                <div className='w-100 px-3'>

                                    <h2 className='tittletd'>Natural language processing</h2>
                                    <p className="descriptiontd">Our chatbots understand and respond to user queries naturally, just like a human.</p>

                                </div>

                            </div>

                        </div>
                       


                       
                    </div>
                    <div className="containerTransMid d-flex justify-content-center row pt-0">
                        <div className="paneltd col-md-4 px-3">

                            <div className='w-100 WrapContainerTrans p-1'>
                                <div className='Img-container '>
                                    <div className='Img_hover'>
                                        <img src={Gen6} alt="" />
                                    </div>

                                </div>
                                <div className='w-100 px-3'>
                                    <h2 className='tittletd'>Continuous learning & improvement</h2>
                                    <p className="descriptiontd">Our chatbots continuously learn and adapt to deliver exceptional customer experiences.</p>
                                </div>

                            </div>
                        </div>


                        <div className="paneltd col-md-4  px-3">
                            <div className='w-100 WrapContainerTrans p-1'>
                                <div className='Img-container '>


                                    <div className='Img_hover'>
                                        <img src={Gen7} alt="" />
                                    </div>

                                </div>
                                <div className='w-100 px-3'>

                                    <h2 className='tittletd'>Seamless integration</h2>
                                    <p className="descriptiontd">Integrate chatbots into your existing platforms for easy access and customer interaction.</p>

                                </div>

                            </div>

                        </div>
                       


                       
                    </div>
                    <div className='col-md-12  px-4  '>
                            <div className='row  NewDesDiv mx-lg-3 py-5'>

                                <div className='col-md-9 px-5 designAre'>
                                    <h5>Are you ready to transform your ideas into reality?</h5>
                                    <p>Develop user-centric web applications with React. Our expertise delivers high-performance and efficient solutions.</p>

                                </div>

                                <div className='col-md-3 btn-design d-flex justify-content-center'>
                                    <a href='https://calendly.com/harsh-46/30min?month=2024-08' target='_blank'>
                                        <button type='submit' className="btn btn-primary btn-visonimpnew  btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={next} alt="" /></button>
                                    </a>

                                </div>

                            </div>

                        </div>



                </div>
                <div className='OurFocusServices'>

                    <h2>Our chatbot development process</h2>

                    <div className='row d-flex justify-content-center mt-3'>
                        <div className='col-md-4'>
                            <div className='designLayout designLayout2'>
                                <h3>Tailored Design</h3>
                                <p>Our seasoned QA professionals are certified and experienced in the latest testing methodologies. We continuously update our skills to stay ahead of the curve.</p>


                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='designLayout designLayout2'>
                                <h3>Extensive testing solutions</h3>
                                <p>We offer a wide range of testing services, including functional, non-functional, automation, performance, and security testing, ensuring your software is robust and reliable.</p>


                            </div>

                        </div>
                       

                    </div>
                    <div className='row d-flex justify-content-center mt-3'>
                        <div className='col-md-4'>
                            <div className='designLayout designLayout2'>
                                <h3>Proven quality processes</h3>
                                <p>Our well-defined methodologies ensure consistent and repeatable results across all projects, regardless of complexity.</p>


                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='designLayout designLayout2'>
                                <h3>Flexible models</h3>
                                <p>We offer tailored engagement options, from dedicated teams to project-based services and staff augmentation, aligned with your business needs and budget.</p>


                            </div>

                        </div>
                       

                    </div>


                </div>
            </>
            ) : null}

        </>

    )
}

export default ServiceCompressive