import React from 'react'
import TransFromBusiness from '../services/TransFromBusiness'
import './blog.scss'
import BlogBanner from './BlogBanner'
import BlogItmes from './BlogItmes'
import BlogSlider from './BlogSlider'
import BrowseBy from './BrowseBy'

function Blog() {
    return (
        <div className='container-fluid overflowHide px-0  mt-3 wow fadeInUp' data-wow-delay="1s">
           <BlogBanner/>
           <BlogItmes/>
           <BlogSlider/>
           <BrowseBy/>
            <TransFromBusiness/>
        </div>
    )
}

export default Blog