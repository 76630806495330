import React from 'react'
import { useNavigate } from 'react-router-dom';

function JoinVikgole({ someProp }) {

    const navigate = useNavigate();

    const handleNavigate = (url) => {
        navigate(url);
    };
    return (
        <div className='JoinVikgole wow fadeInUp' data-wow-delay="0.5s">

            <h3 className='mainHead'>{someProp}</h3>

            <p className='DetailHead'>We're on the lookout for passionate individuals to be part of our innovative journey. Discover exciting opportunities to grow your career while contributing to groundbreaking projects.</p>

            <div className='row mt-lg-5 px-lg-5'>
                <div className='col-md-4 d-flex align-itemes-center' onClick={() => handleNavigate(`/careers/detail?jobType=Nodejs`)}>

                    <div className='designlayout'>

                        <div className='justpost'>
                            Just posted

                        </div>

                        <h3>Node.js Developer </h3>
                        <h2 >Bangalore  |  <span > Full Time</span></h2>
                        <p >We seek a skilled Node.js Developer to join our team, focusing on building scalable, high-performance applications. Responsibilities include designing, developing, and maintaining backend services, ensuring efficiency, reliability, and performance.</p>
                        <h4 > Read more </h4>
                    </div>

                </div>
                <div className='col-md-4' onClick={() => handleNavigate(`/careers/detail?jobType=Flutter`)}>
                    <div className='designlayout'>

                        <div className='justpost'>
                            Just posted

                        </div>

                        <h3 className='op8'>Flutter Developer  </h3>
                        <h2 className='op8'>Bangalore  |  <span > Full Time</span></h2>
                        <p className='op8'>We seek a skilled Flutter Developer to join our team, focusing on creating beautiful, high-performance mobile applications. Responsibilities include designing, developing, and maintaining cross-platform apps, ensuring seamless user experiences. </p>
                        <h4 className='op8'>Read more </h4>
                    </div>
                </div>
                <div className='col-md-4' onClick={() => handleNavigate(`/careers/detail?jobType=python`)}>
                    <div className='designlayout'>

                        <div className='justpost'>
                            Just posted

                        </div>

                        <h3 className='op8'>Python Developer </h3>
                        <h2 className='op8'>Bangalore  |  <span > Full Time</span></h2>
                        <p className='op8'>We seek a skilled Python Developer to join our team, focusing on building robust, scalable applications. Responsibilities include designing, developing, and maintaining backend systems, ensuring code quality, performance, and reliability.</p>
                        <h4 className='op8'>Read more </h4>
                    </div>
                </div>

            </div>
            <div className='row mt-lg-5 px-lg-5'>
                <div className='col-md-4 d-flex align-itemes-center' onClick={() => handleNavigate(`/careers/detail?jobType=DevOps`)}>

                    <div className='designlayout'>


                        <h3 className='op7'>DevOps </h3>
                        <h2 className='op7'>San Francisco, CA  |  <span > Full Time</span></h2>
                        <p className='op7'>We seek a skilled DevOps Engineer to automate and optimize development and deployment processes. Responsibilities include managing CI/CD pipelines, maintaining infrastructure, ensuring system reliability, and collaborating with development teams to enhance operational efficiency and performance.</p>
                        <h4 className='op7'>Read more </h4>
                    </div>

                </div>
                <div className='col-md-4' onClick={() => handleNavigate(`/careers/detail?jobType=react`)}>
                    <div className='designlayout'>



                        <h3 className='op7'>React js Developer</h3>
                        <h2 className='op7'>San Francisco, CA  |  <span > Full Time</span></h2>
                        <p className='op7'>We seek a skilled React.js Developer to join our team, focusing on building dynamic and responsive user interfaces. Responsibilities include designing, developing, and maintaining frontend applications, ensuring performance, scalability, and a seamless user experience, and integrating with backend services.</p>
                        <h4 className='op7'>Read more </h4>
                    </div>
                </div>
                <div className='col-md-4' onClick={() => handleNavigate(`/careers/detail?jobType=angular`)}>
                    <div className='designlayout'>



                        <h3 className='op7'>Angular Developer </h3>
                        <h2 className='op7'>San Francisco, CA |  <span > Full Time</span></h2>
                        <p className='op7'>We seek a skilled Angular Developer to join our team, focusing on creating dynamic and scalable web applications. Responsibilities include designing, developing, and maintaining frontend systems, ensuring high performance, code quality, and a seamless user experience.</p>
                        <h4 className='op7'>Read more </h4>
                    </div>
                </div>

            </div>
         

        </div>
    )
}

export default JoinVikgole
