import React from 'react'
import { PromiseIc1, PromiseIc2 } from '../../assets'

function OurPromise() {
    return (
        <div className='OurPromiseSection wow fadeInUp' data-wow-delay="0.5s">

            <h1>Our promise. Our focus.</h1>

            <div className='row mt-lg-4'>

                <div className='col-md-6'>

                    <div className='PromiseDesign'>

                        <img src={PromiseIc1} alt="" />

                        <h3>Our Mission</h3>

                        <p>To be a trusted technology partner that empowers businesses to achieve their full potential. We strive to deliver exceptional software solutions that drive efficiency, enhance customer experiences, and generate tangible business results. Our mission is to be at the forefront of technological advancements, providing our clients with a competitive edge in today's rapidly evolving digital landscape.</p>

                    </div>

                </div>
                <div className='col-md-6'>

                    <div className='PromiseDesign'>

                    <img src={PromiseIc2} alt="" />

                    <h3>Our Vision</h3>

                    <p>To become the preferred choice for businesses seeking world-class software development services. We envision a future where Vikgol is synonymous with innovation, quality, and customer satisfaction. By leveraging cutting-edge technologies and a deep understanding of market trends, we aim to shape the future of the software industry.</p>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default OurPromise