import React from 'react'
import { TransIc1, TransIc10, TransIc11, TransIc12, TransIc2, TransIc3, TransIc4, TransIc5, TransIc6, TransIc7, TransIc8, TransIc9, next } from '../../assets';

function WhyClients() {
  return (
    <div className='TransformingIdeas TransIdeaMid py-5 w-100 wow fadeInUp ' data-wow-delay ="0.5s" >
            <h1 className="headerT headerTBt">Why Our Clients Trust Vikgol for Exceptional Software Development</h1>

           
            <div className="containerTransMid row mt-lg-5">
                <div className="paneltd col-md-4 px-3">

                    <div className='w-100 WrapContainerTrans p-1'>
                        <div className='Img-container '>
                            <div className='Img_hover'>
                                <img src={TransIc7} alt="" />
                            </div>

                        </div>
                        <div className='w-100 px-3'>
                            <h2 className='tittletd'>Client-Centric Approach</h2>
                            <p className="descriptiontd">Our approach begins and ends with you – our client. With a client-centric focus, we tailor our solutions to your unique needs, ensuring your satisfaction drives every step of our process.</p>
                        </div>

                    </div>
                </div>


                <div className="paneltd col-md-4  px-3">
                    <div className='w-100 WrapContainerTrans p-1'>
                        <div className='Img-container '>


                            <div className='Img_hover'>
                                <img src={TransIc8} alt="" />
                            </div>

                        </div>
                        <div className='w-100 px-3'>

                            <h2 className='tittletd'>User-Centric Design</h2>
                            <p className="descriptiontd">By empathising with your audience, we create interfaces that provide intuitive and delightful interactions, fostering a lasting connection between users and your product.</p>

                        </div>

                    </div>

                </div>
                <div className="paneltd col-md-4  px-2">
                    <div className='w-100 WrapContainerTrans p-1'>
                        <div className='Img-container '>
                            <div className='Img_hover'>
                                <img src={TransIc9} alt="" />
                            </div>

                        </div>
                        <div className='w-100 px-3'>
                            <h2 className='tittletd'>Innovative Team</h2>
                            <p className="descriptiontd">With innovation woven into our DNA, our team's dedication to creative solutions ensures that your projects are infused with the latest trends and groundbreaking ideas.</p>
                        </div>

                    </div>

                </div>
                <div className="paneltd col-md-4 px-3">

                    <div className='w-100 WrapContainerTrans p-1'>
                        <div className='Img-container '>
                            <div className='Img_hover'>
                                <img src={TransIc10} alt="" />
                            </div>

                        </div>
                        <div className='w-100 px-3'>
                            <h2 className='tittletd'>Collaborative Approach</h2>
                            <p className="descriptiontd">Embracing a collaborative approach, Your insights and feedback guide us every step, ensuring the end product reflects our combined vision and expertise.</p>
                        </div>

                    </div>
                </div>
                <div className="paneltd col-md-4 px-3">

                    <div className='w-100 WrapContainerTrans p-1'>
                        <div className='Img-container '>
                            <div className='Img_hover'>
                                <img src={TransIc11} alt="" />
                            </div>

                        </div>
                        <div className='w-100 px-3'>
                            <h2 className='tittletd'>Timely Delivery</h2>
                            <p className="descriptiontd">We ensure your projects are completed within the agreed-upon schedules, maintaining the highest quality standards throughout the process.
</p>
                        </div>

                    </div>
                </div>
                <div className="paneltd col-md-4 px-3">

                    <div className='w-100 WrapContainerTrans p-1'>
                        <div className='Img-container '>
                            <div className='Img_hover'>
                                <img src={TransIc12} alt="" />
                            </div>

                        </div>
                        <div className='w-100 px-3'>
                            <h2 className='tittletd'>Comprehensive Support</h2>
                            <p className="descriptiontd">Beyond development, we stand by your side with continuous updates, maintenance, and assistance, ensuring your software remains at its best long after deployment.</p>
                        </div>

                    </div>
                </div>

                <div className='col-md-12  px-4 '>
                    <div className='row  NewDesDiv py-5'>

                        <div className='col-md-9 px-5 designAre'>
                          <h5>Are you ready to transform your ideas into reality?</h5>
                          <p>we craft innovative software solutions tailored to your unique needs. Experience excellence with our dedicated and client-focused approach.</p>

                        </div>

                        <div className='col-md-3 btn-design d-flex justify-content-center'>
                            <a href='https://calendly.com/harsh-46/30min?month=2024-08' target='_blank'>
                            <button type='submit' className="btn btn-primary btn-visonimpnew  btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={next} alt="" /></button>
                            </a>
                       
                        </div>

                    </div>

                </div>
            </div>



        </div>
  )
}

export default WhyClients