import './App.scss';
import AppRouter from './routes/Router';





function App() {
  return (
   <>
  <AppRouter/>
   </>
  );
}

export default App;
