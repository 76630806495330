import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { Breifcase, Clipboard, Close, File, location, next, uploadIcon } from '../../assets';
import { useLocation } from 'react-router-dom';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const ApplicationForm = ({ onClose }) => {
    const query = useQuery();
    const searchTerm = query.get('jobType');
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [cvFile, setCvFile] = useState(null);



    const onDrop = useCallback(acceptedFiles => {
        setCvFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.pdf,.doc,.docx',
        maxSize: 10485760 // 10MB
    });

    const onSubmit = data => {
        console.log({ ...data, cvFile });
        // Handle form submission here
    };

    return (
        <>

            <div className="modal fade modal-pad" tabIndex={-1} id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg-cust modal-dialog-centered">
                    <div className="modal-content Form-modal">
                        <div className="modal-body popupFormSection ">

                            <div className="application-form-container">
                                <div className='heading-container'>
                                    {searchTerm === 'Nodejs'?(<h2>Node js Developer  <img src={Close} alt="" id="modalPopupCloseBtn" className='' data-bs-dismiss="modal" /></h2>):null}
                                    {searchTerm === 'Flutter'?(<h2>Flutter Developer  <img src={Close} alt="" id="modalPopupCloseBtn" className='' data-bs-dismiss="modal" /></h2>):null}
                                    {searchTerm === 'python'?(<h2>Python Developer  <img src={Close} alt="" id="modalPopupCloseBtn" className='' data-bs-dismiss="modal" /></h2>):null}
                                    {searchTerm === 'DevOps'?(<h2>DevOps  <img src={Close} alt="" id="modalPopupCloseBtn" className='' data-bs-dismiss="modal" /></h2>):null}
                                    {searchTerm === 'react'?(<h2>React js Developer   <img src={Close} alt="" id="modalPopupCloseBtn" className='' data-bs-dismiss="modal" /></h2>):null}
                                    {searchTerm === 'angular'?(<h2>Angular Developer   <img src={Close} alt="" id="modalPopupCloseBtn" className='' data-bs-dismiss="modal" /></h2>):null}
                                    

                                    <h3 className='headingrole'><img src={location} alt="" /> Bangalore, India &nbsp;&nbsp; &nbsp;&nbsp;  <img src={Breifcase} alt="" /> Full-Time  &nbsp;&nbsp;  &nbsp;&nbsp;<img src={Clipboard} alt="" /> 01 Vacancy</h3>

                                </div>


                                <form onSubmit={handleSubmit(onSubmit)} className="  g-3 mt-lg-4">
                                    <div className='row formDesign mb-3'>

                                        <div className="col-md-6">
                                            <label className="form-label">Full Name *</label>
                                            <input type="text" placeholder="Enter your Full Name" className={`form-control ${errors.fullName && 'is-invalid'}`} {...register('fullName', { required: 'Full Name is required' })} />
                                            {errors.fullName && <div className="invalid-feedback">{errors.fullName.message}</div>}
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Total Experience *</label>
                                            <input type="text" placeholder="Enter your Experience" className={`form-control ${errors.totalExperience && 'is-invalid'}`} {...register('totalExperience', { required: 'Total Experience is required' })} />
                                            {errors.totalExperience && <div className="invalid-feedback">{errors.totalExperience.message}</div>}
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Last Time *</label>
                                            <input type="text" placeholder="Enter your Last Time " className={`form-control ${errors.lastTime && 'is-invalid'}`} {...register('lastTime', { required: 'Last Time is required' })} />
                                            {errors.lastTime && <div className="invalid-feedback">{errors.lastTime.message}</div>}
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Current CTC *</label>
                                            <input type="text" placeholder="Enter your Current CTC " className={`form-control ${errors.currentCTC && 'is-invalid'}`} {...register('currentCTC', { required: 'Current CTC is required' })} />
                                            {errors.currentCTC && <div className="invalid-feedback">{errors.currentCTC.message}</div>}
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Mobile *</label>
                                            <input type="text" placeholder="Enter your Mobile" className={`form-control ${errors.mobile && 'is-invalid'}`} {...register('mobile', { required: 'Mobile is required' })} />
                                            {errors.mobile && <div className="invalid-feedback">{errors.mobile.message}</div>}
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Email *</label>
                                            <input type="email" placeholder="Enter your Email" className={`form-control ${errors.email && 'is-invalid'}`} {...register('email', { required: 'Email is required' })} />
                                            {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Expected CTC *</label>
                                            <input type="text" placeholder="Expected CTC" className={`form-control ${errors.expectedCTC && 'is-invalid'}`} {...register('expectedCTC', { required: 'Expected CTC is required' })} />
                                            {errors.expectedCTC && <div className="invalid-feedback">{errors.expectedCTC.message}</div>}
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Upload CV *</label>
                                            <div {...getRootProps({ className: `drag-drop-area ${isDragActive ? 'drag-active' : ''}` })}>
                                                <input {...getInputProps()} />
                                                <div className='row'>
                                                    <div className='col-12 row px-3'>
                                                        <div className='col-2  justify-content-center d-flex align-itmes-center'>
                                                           <img src={uploadIcon} alt="" />
                                                        </div>
                                                        <div className='col-7'>

                                                            <h5 className='mt-3'>Drag & drop your CV here</h5>
                                                            <p>JPG, PNG or PDF, file size no more than 10MB</p>


                                                        </div>
                                                        <div className='col-3 justify-content-center d-flex align-itmes-center'>
                                                            <button> Select file</button>

</div>
                                                    </div>



                                                </div>


                                                {cvFile && <p className="file-name mt-2">Filename : {cvFile.name}</p>}
                                            </div>
                                            {errors.cv && <div className="invalid-feedback">{errors.cv.message}</div>}
                                        </div>

                                    </div>


                                    <div className="button-group">
                                        <p onClick={() => reset()} className='cursorpointer'>Clear Form</p>
                                        <button type='submit' className="btn btn-primary btn-visonimp  btn-lg"
                                        ><span>Apply now</span> <img className='mt-1 imgNext' src={next} alt="" /></button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </>
    );
}

export default ApplicationForm;