import React from 'react'
import TransFromBusiness from '../services/TransFromBusiness'
import './aboutus.scss';
import AboutBanner from './AboutBanner';
import JourneyOur from './JourneyOur';
import OurPromise from './OurPromise';
import ChooseVikgole from './ChooseVikgole';
import MyTeam from './MyTeam';






function Aboutus() {
  return (
    <div className='container-fluid overflowHide px-0  mt-lg-3 wow fadeInUp' data-wow-delay ="1s">
        <AboutBanner/>
         <JourneyOur/>
          <MyTeam/>
         <OurPromise/>
         <ChooseVikgole/>
        <TransFromBusiness/>
    </div>
  )
}

export default Aboutus