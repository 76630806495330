import React from 'react'
import './header.scss';
import { NavLink } from 'react-router-dom';


import { Insta, LOGO, Linkedin, fb, twitter } from '../../assets';

function Header() {
  return (
    <nav className="navbar stickynav navbar-expand-lg navbar-light  pt-3 px-3">
    <div className="container-fluid">
    <NavLink to="/">
    <a className="navbar-brand " >
        <img src={LOGO}   alt="Vikgol" className="d-inline-block align-top" /> 
      </a>
    </NavLink>
     
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
            <NavLink className="nav-link" to="/" activeClassName="active">
              Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/services" activeClassName="active">
              Services
            </NavLink>
          </li>
         
          <li className="nav-item">
            <NavLink className="nav-link" to="/careers" activeClassName="active">
              Careers
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/blog" activeClassName="active" >
              Blog
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/about-us" activeClassName="active" >
              About Us
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/contact-us" activeClassName="active"  >
              Contact Us
            </NavLink>
          </li>
        </ul>
        <div className="d-flex justify-content-center">
          <a href="https://www.facebook.com/vikgols/" className="socialIcon me-2">
            <img src={fb} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/vikgol/" className="socialIcon me-2">
            <img src={Insta} alt="Instagram" />
          </a>
          <a href="https://x.com/vikgols?lang=en" className="socialIcon x-app me-2">
            <img src={twitter} alt="Twitter" />
          </a>
          <a href="https://in.linkedin.com/company/vikgol" className="socialIcon">
            <img src={Linkedin} alt="LinkedIn" />
          </a>
        </div>
      </div>
    </div>
  </nav>
  
  
  )
}

export default Header


