import React from 'react'
import { BgIcon1, BgIcon10, BgIcon2, BgIcon3, BgIcon4, BgIcon5, BgIcon6, BgIcon7, BgIcon8, BgIcon9 } from '../../assets'

function BrowseBy() {
    return (
        <div className='BrowseBy wow fadeInUp '  data-wow-delay="0.5s">
            <h2>Browse by topic</h2>
            <div className='row mt-lg-4'>
                <div className='col-md-4'>

                    <div className='Design_layout'>
                      <img src={BgIcon1} alt="" />
                       <h2>Web App Development</h2>
                    </div>

                </div>
                <div className='col-md-4'>
                    <div className='Design_layout'>
                    <img src={BgIcon2} alt="" />
                    <h2>Mobile App Development</h2>
                    </div>

                </div>
                <div className='col-md-4'>
                    <div className='Design_layout'>
                    <img src={BgIcon3} alt="" />
                    <h2>Software Testing</h2>
                    </div>

                </div>

            </div>
            <div className='row mt-lg-4'>
                <div className='col-md-4'>

                    <div className='Design_layout'>
                    <img src={BgIcon4} alt="" />
                    <h2>DevOps</h2>
                    </div>

                </div>
                <div className='col-md-4'>
                    <div className='Design_layout'>
                    <img src={BgIcon5} alt="" />
                    <h2>UI/UX Design</h2>
                    </div>

                </div>
                <div className='col-md-4'>
                    <div className='Design_layout'>
                    <img src={BgIcon6} alt="" />
                    <h2>Digital Marketing</h2>
                    </div>

                </div>

            </div>
            <div className='row mt-lg-4'>
                <div className='col-md-4'>

                    <div className='Design_layout'>
                    <img src={BgIcon7} alt="" />
                    <h2>Blockchain Development</h2>
                    </div>

                </div>
                <div className='col-md-4'>
                    <div className='Design_layout'>
                    <img src={BgIcon8} alt="" />
                    <h2>AR & Mixed Reality Lab</h2>
                    </div>

                </div>
                <div className='col-md-4'>
                    <div className='Design_layout'>
                    <img src={BgIcon9} alt="" />
                    <h2>Artificial Intelligence</h2>
                    </div>

                </div>

            </div>
            <div className='row mt-lg-4 justify-content-center'>
                <div className='col-md-4'>

                    <div className='Design_layout'>
                    <img src={BgIcon10} alt="" />
                    <h2>Internet of Things</h2>
                    </div>

                </div>
               

            </div>
          
        </div>
    )
}

export default BrowseBy