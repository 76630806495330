import React from 'react'
import { next } from '../../assets'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function CareerDescription() {
    const navigate = useNavigate();
    const query = useQuery();
    const searchTerm = query.get('jobType');

    return (
        <>
            {searchTerm === 'Nodejs' ? (<div className='careerDescription '>
                <h3>Who we are</h3>

                <p> Vikgol ignited in 2018, fuelled by a relentless pursuit of digital innovation. We’ve transformed challenges into graphics, creating digital symphonies that redefine industries. With expertise in [list of core services, e.g., web development, mobile app development, AI, cloud solutions], we deliver cutting-edge solutions tailored to your business goals. Join us in shaping the future. </p>

                <h3>Requirements</h3>

                <ul >
                    <li>Experience with responsive and adaptive design</li>
                    <li>Understanding of user research and usability testing methodologies</li>
                    <li>Familiarity with front-end development (HTML, CSS, JavaScript) to communicate effectively with developers</li>
                    <li>Knowledge of accessibility standards and best practices</li>
                    <li>Experience with prototyping and wireframing tools</li>
                    <li>Attention to detail and a passion for creating visually appealing designs</li>
                </ul>

                <h3>Benefits</h3>
                <ul className='TickList'>
                    <li>Proven experience in developing scalable backend applications using Node.js</li>
                    <li>Strong proficiency in JavaScript and asynchronous programming</li>
                    <li>Experience with server-side frameworks (e.g., Express.js, Koa)</li>
                    <li>Knowledge of integrating with databases (e.g., MongoDB, PostgreSQL) and RESTful APIs</li>
                    <li>Ability to work with version control systems (e.g., Git)</li>
                    <li>Excellent problem-solving skills and attention to detail</li>
                    <li>A portfolio or examples of previous Node.js projects</li>
                </ul>

                <h3>Salary</h3>

                <p>Based on Skills [50K - 70K (INR)] and Other Benefits</p>

                <h3>Job Nature</h3>

                <p>This is a full-time position based in Bangalore. You will be part of a fast-paced and agile development team, working on a variety of exciting projects.</p>

                <a data-bs-toggle="modal"
                    data-bs-target="#exampleModal" className="btn btn-primary btn-visonimp  btn-lg"><span>Apply now</span> <img className='mt-1 imgNext' src={next} alt="" /></a>

            </div>) : null}
            {searchTerm === 'Flutter' ? (<div className='careerDescription '>
                <h3>Who we are</h3>

                <p> Vikgol ignited in 2018, fuelled by a relentless pursuit of digital innovation. We’ve transformed challenges into graphics, creating digital symphonies that redefine industries. With expertise in [list of core services, e.g., web development, mobile app development, AI, cloud solutions], we deliver cutting-edge solutions tailored to your business goals. Join us in shaping the future. </p>

                <h3>Requirements</h3>

                <ul>
                    <li>Proven experience in developing cross-platform mobile applications using Flutter</li>
                    <li>Strong proficiency in Dart programming language</li>
                    <li>Deep understanding of Flutter widgets and state management</li>
                    <li>Experience with integrating RESTful APIs and third-party libraries</li>
                    <li>Ability to work effectively with version control systems (e.g., Git)</li>
                    <li>Excellent problem-solving skills and attention to detail</li>
                    <li>A portfolio or examples of previous Flutter applications</li>
                </ul>


                <h3>Benefits</h3>
                <ul className='TickList'>
                    <li>Proven experience in developing cross-platform mobile applications using Flutter</li>
                    <li>Strong proficiency in Dart programming language</li>
                    <li>Experience with Flutter widgets, state management, and asynchronous programming</li>
                    <li>Knowledge of integrating with RESTful APIs and third-party libraries</li>
                    <li>Ability to work with version control systems (e.g., Git)</li>
                    <li>Excellent problem-solving skills and attention to detail</li>
                    <li>A portfolio or examples of previous Flutter applications</li>
                </ul>

                <h3>Salary</h3>

                <p>Based on Skills [40K - 60K (INR) ] and Other Benefits</p>

                <h3>Job Nature</h3>

                <p>This is a full-time position based in Bangalore. You will be part of a fast-paced and agile development team, working on a variety of exciting projects.</p>

                <a data-bs-toggle="modal"
                    data-bs-target="#exampleModal" className="btn btn-primary btn-visonimp  btn-lg"><span>Apply now</span> <img className='mt-1 imgNext' src={next} alt="" /></a>

            </div>) : null}
            {searchTerm === 'python' ? (<div className='careerDescription '>
                <h3>Who we are</h3>

                <p> Vikgol ignited in 2018, fuelled by a relentless pursuit of digital innovation. We’ve transformed challenges into graphics, creating digital symphonies that redefine industries. With expertise in [list of core services, e.g., web development, mobile app development, AI, cloud solutions], we deliver cutting-edge solutions tailored to your business goals. Join us in shaping the future. </p>

                <h3>Requirements</h3>

                <ul>
                    <li>Proven experience in developing robust applications using Python</li>
                    <li>Strong proficiency in Python programming language and libraries</li>
                    <li>Experience with web frameworks such as Django or Flask</li>
                    <li>Knowledge of integrating with databases (e.g., PostgreSQL, MySQL) and RESTful APIs</li>
                    <li>Ability to work effectively with version control systems (e.g., Git)</li>
                    <li>Excellent problem-solving skills and attention to detail</li>
                    <li>A portfolio or examples of previous Python projects</li>
                </ul>

                <h3>Benefits</h3>
                <ul className='TickList'>
                    <li>Proven experience in developing robust applications using Python</li>
                    <li>Strong proficiency in Python programming language and libraries</li>
                    <li>Experience with web frameworks such as Django or Flask</li>
                    <li>Knowledge of integrating with databases (e.g., PostgreSQL, MySQL) and RESTful APIs</li>
                    <li>Ability to work with version control systems (e.g., Git)</li>
                    <li>Excellent problem-solving skills and attention to detail</li>
                    <li>A portfolio or examples of previous Python projects</li>
                </ul>


                <h3>Salary</h3>

                <p>Based on Skills [60K - 70K (INR) ] and Other Benefits</p>

                <h3>Job Nature</h3>

                <p>This is a full-time position based in Bangalore. You will be part of a fast-paced and agile development team, working on a variety of exciting projects.</p>

                <a data-bs-toggle="modal"
                    data-bs-target="#exampleModal" className="btn btn-primary btn-visonimp  btn-lg"><span>Apply now</span> <img className='mt-1 imgNext' src={next} alt="" /></a>

            </div>) : null}
            {searchTerm === 'DevOps' ? (<div className='careerDescription '>
                <h3>Who we are</h3>

                <p> Vikgol ignited in 2018, fuelled by a relentless pursuit of digital innovation. We’ve transformed challenges into graphics, creating digital symphonies that redefine industries. With expertise in [list of core services, e.g., web development, mobile app development, AI, cloud solutions], we deliver cutting-edge solutions tailored to your business goals. Join us in shaping the future. </p>

                <h3>Requirements</h3>

                <ul>
                    <li>Proven experience in automating and optimizing deployment processes</li>
                    <li>Strong proficiency in CI/CD tools and pipelines</li>
                    <li>Experience with cloud platforms (e.g., AWS, Azure, GCP)</li>
                    <li>Knowledge of infrastructure as code tools (e.g., Terraform, Ansible)</li>
                    <li>Ability to manage and monitor systems and applications</li>
                    <li>Excellent problem-solving skills and attention to detail</li>
                    <li>A portfolio or examples of previous DevOps projects</li>
                </ul>
                <h3>Benefits</h3>
                <ul className='TickList'>
                    <li>Proven experience in automating and optimizing deployment processes</li>
                    <li>Strong proficiency in CI/CD tools and pipelines</li>
                    <li>Experience with cloud platforms (e.g., AWS, Azure, GCP)</li>
                    <li>Knowledge of infrastructure as code tools (e.g., Terraform, Ansible)</li>
                    <li>Ability to manage and monitor systems and applications</li>
                    <li>Excellent problem-solving skills and attention to detail</li>
                    <li>A portfolio or examples of previous DevOps projects</li>
                </ul>


                <h3>Salary</h3>

                <p>Based on Skills [80K - 90K (INR) ] and Other Benefits</p>

                <h3>Job Nature</h3>

                <p>This is a full-time position based in Bangalore. You will be part of a fast-paced and agile development team, working on a variety of exciting projects.</p>

                <a data-bs-toggle="modal"
                    data-bs-target="#exampleModal" className="btn btn-primary btn-visonimp  btn-lg"><span>Apply now</span> <img className='mt-1 imgNext' src={next} alt="" /></a>

            </div>) : null}
            {searchTerm === 'react' ? (<div className='careerDescription '>
                <h3>Who we are</h3>

                <p> Vikgol ignited in 2018, fuelled by a relentless pursuit of digital innovation. We’ve transformed challenges into graphics, creating digital symphonies that redefine industries. With expertise in [list of core services, e.g., web development, mobile app development, AI, cloud solutions], we deliver cutting-edge solutions tailored to your business goals. Join us in shaping the future. </p>

                <h3>Requirements</h3>

                <ul>
                    <li>Proven experience in developing interactive and responsive web applications using React.js</li>
                    <li>Strong proficiency in JavaScript and modern ES6+ syntax</li>
                    <li>Experience with state management libraries (e.g., Redux, Context API)</li>
                    <li>Knowledge of frontend build tools and version control systems (e.g., Webpack, Git)</li>
                    <li>Ability to integrate with backend services and RESTful APIs</li>
                    <li>Excellent problem-solving skills and attention to detail</li>
                    <li>A portfolio or examples of previous React.js projects</li>
                </ul>
                <h3>Benefits</h3>
                <ul className='TickList'>
                    <li>Proven experience in developing dynamic web applications using React</li>
                    <li>Strong proficiency in JavaScript, including ES6+ features</li>
                    <li>Experience with React hooks, context API, and state management (e.g., Redux)</li>
                    <li>Knowledge of integrating RESTful APIs and third-party libraries</li>
                    <li>Ability to work with version control systems (e.g., Git)</li>
                    <li>Excellent problem-solving skills and attention to detail</li>
                    <li>A portfolio or examples of previous React projects</li>
                </ul>

                <h3>Salary</h3>

                <p>Based on Skills [80K - 90K (INR) ] and Other Benefits</p>

                <h3>Job Nature</h3>

                <p>This is a full-time position based in Bangalore. You will be part of a fast-paced and agile development team, working on a variety of exciting projects.</p>

                <a data-bs-toggle="modal"
                    data-bs-target="#exampleModal" className="btn btn-primary btn-visonimp  btn-lg"><span>Apply now</span> <img className='mt-1 imgNext' src={next} alt="" /></a>

            </div>) : null}
            {searchTerm === 'angular' ? (<div className='careerDescription '>
                <h3>Who we are</h3>

                <p> Vikgol ignited in 2018, fuelled by a relentless pursuit of digital innovation. We’ve transformed challenges into graphics, creating digital symphonies that redefine industries. With expertise in [list of core services, e.g., web development, mobile app development, AI, cloud solutions], we deliver cutting-edge solutions tailored to your business goals. Join us in shaping the future. </p>

                <h3>Requirements</h3>

                <ul>
                    <li>Proven experience in developing web applications using Angular</li>
                    <li>Strong proficiency in TypeScript and modern Angular frameworks</li>
                    <li>Experience with state management libraries (e.g., NgRx)</li>
                    <li>Knowledge of frontend build tools and version control systems (e.g., Webpack, Git)</li>
                    <li>Ability to integrate with backend services and RESTful APIs</li>
                    <li>Excellent problem-solving skills and attention to detail</li>
                    <li>A portfolio or examples of previous Angular projects</li>
                </ul>
                <h3>Benefits</h3>
                <ul className='TickList'>
                    <li>Proven experience in developing web applications using Angular</li>
                    <li>Strong proficiency in TypeScript and Angular frameworks</li>
                    <li>Experience with Angular CLI, components, services, and modules</li>
                    <li>Knowledge of state management libraries (e.g., NgRx) and integrating RESTful APIs</li>
                    <li>Ability to work with version control systems (e.g., Git)</li>
                    <li>Excellent problem-solving skills and attention to detail</li>
                    <li>A portfolio or examples of previous Angular projects</li>
                </ul>

                <h3>Salary</h3>

                <p>Based on Skills [80K - 90K (INR) ] and Other Benefits</p>

                <h3>Job Nature</h3>

                <p>This is a full-time position based in Bangalore. You will be part of a fast-paced and agile development team, working on a variety of exciting projects.</p>

                <a data-bs-toggle="modal"
                    data-bs-target="#exampleModal" className="btn btn-primary btn-visonimp  btn-lg"><span>Apply now</span> <img className='mt-1 imgNext' src={next} alt="" /></a>

            </div>) : null}

        </>


    )
}

export default CareerDescription