import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTopOnRouteChange() {
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0); // Adjust the timeout duration if needed

    return () => clearTimeout(timer);
  }, [location]);

  return null; // This component does not render anything
}

export default ScrollToTopOnRouteChange;
