import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CommonLayout from '../layouts/CommonLayout';
import { PublicRoute } from './routeConfig';
import ScrollToTopOnRouteChange from '../components/ScrollToTopOnRouteChange';

function AppRouter() {


  return (
    <Router>

   <ScrollToTopOnRouteChange />
      
    <Routes>
      {/* Use Layout for routes where you want a consistent structure */}
      <Route path="/" element={< CommonLayout/>}>
        {PublicRoute.map((route) => (
          <Route key={route.path} path={route.path} element={route.component} />
        ))}
      </Route>
    </Routes>
  </Router>
  
  )
}

export default AppRouter