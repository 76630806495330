import React from 'react'
import ContactForm from '../home/GETInTouch'
import TransFromBusiness from '../services/TransFromBusiness'
import './Contact.scss';
import ContactBanner from './ContactBanner';
import GoogleAddress from './GoogleAddress';

function Contact() {
  return (
    <div className='container-fluid overflowHide px-0  mt-3'>
      <ContactBanner/>
      <ContactForm/>
      <GoogleAddress/>
      <TransFromBusiness/>

    </div>
  )
}

export default Contact