import React from 'react'
import FuelInvoation from '../home/FuelInvoation'
import AreUReady from '../home/AreUReady'
import BannerServices from './BannerServices'
import './servicesPage.scss';
import WePropel from './WePropel';
import TransFromBusiness from './TransFromBusiness';
import WhyClients from './WhyClients';

function Services() {
    return (
        <div className='container-fluid overflowHide px-0  mt-3 wow fadeInUp' data-wow-delay ="1s">

            <BannerServices />
            <WePropel/>
            <TransFromBusiness/>
            <WhyClients/>
            <FuelInvoation />
            <AreUReady />

        </div>
    )
}

export default Services