import React from 'react'
import { Iconcm1, Iconcm2, Iconcm3, Iconcm4, Iconcm5, Iconcm6 } from '../../assets'
import { useLocation } from 'react-router-dom';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function CompressiveAdd() {
    const query = useQuery();
    const searchTerm = query.get('ServiceType');
    return (
        <>
         {searchTerm == 'web_app_development'?(<div className='CompressiveAdd wow fadeInUp' data-wow-delay ="0.5s">
            <h2> Comprehensive web development services </h2>
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <img src={Iconcm1} alt="" />

                    <h3>
                    Discovery and Planning
                    </h3>
                    <p>
                    In-depth analysis of your business goals, target audience, and requirements.
                    </p>
    
                </div>
                <div className='col-md-4 text-center'>
                    <img src={Iconcm2} alt="" />

                    <h3>
                    Wireframing and Prototyping
                    </h3>
                    <p>
                    Creation of low-fidelity wireframes and interactive prototypes.
                    </p>
    
                </div>
                <div className='col-md-4 text-center'>
                    <img src={Iconcm3} alt="" />

                    <h3>
                    Design and User Experience
                    </h3>
                    <p>
                    Design of visually appealing and user-friendly interfaces.
                    </p>
    
                </div>

            </div>

            <div className='row'>
                <div className='col-md-4 text-center'>
                    <img src={Iconcm4} alt="" />

                    <h3>
                    Development and Integration
                    </h3>
                    <p>
                    Building robust, scalable, and secure web applications.
                    </p>
    
                </div>
                <div className='col-md-4 text-center'>
                    <img src={Iconcm5} alt="" />

                    <h3>
                    Testing and Quality Assurance
                    </h3>
                    <p>
                    Rigorous testing across multiple browsers, devices, and scenarios.
                    </p>
    
                </div>
                <div className='col-md-4 text-center'>
                    <img src={Iconcm6} alt="" />

                    <h3>
                    Deployment and Maintenance
                    </h3>
                    <p>
                    Smooth transition to production and ongoing maintenance.
                    </p>
    
                </div>

            </div>

        </div>):null}
        {searchTerm != 'web_app_development'?(<div className='CompressiveAdd wow fadeInUp' data-wow-delay ="0.5s">
            <h2> Comprehensive Mobile App Development Services </h2>
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <img src={Iconcm1} alt="" />

                    <h3>
                    Discovery and Planning
                    </h3>
                    <p>
                    In-depth analysis of your business goals, target audience, and requirements.
                    </p>
    
                </div>
                <div className='col-md-4 text-center'>
                    <img src={Iconcm2} alt="" />

                    <h3>
                    Wireframing and Prototyping
                    </h3>
                    <p>
                    Creation of low-fidelity wireframes and interactive prototypes.
                    </p>
    
                </div>
                <div className='col-md-4 text-center'>
                    <img src={Iconcm3} alt="" />

                    <h3>
                    Design and User Experience
                    </h3>
                    <p>
                    Design of visually appealing and user-friendly interfaces.
                    </p>
    
                </div>

            </div>

            <div className='row'>
                <div className='col-md-4 text-center'>
                    <img src={Iconcm4} alt="" />

                    <h3>
                    Development and Integration
                    </h3>
                    <p>
                    Building robust, scalable, and secure web applications.
                    </p>
    
                </div>
                <div className='col-md-4 text-center'>
                    <img src={Iconcm5} alt="" />

                    <h3>
                    Testing and Quality Assurance
                    </h3>
                    <p>
                    Rigorous testing across multiple browsers, devices, and scenarios.
                    </p>
    
                </div>
                <div className='col-md-4 text-center'>
                    <img src={Iconcm6} alt="" />

                    <h3>
                    Deployment and Maintenance
                    </h3>
                    <p>
                    Smooth transition to production and ongoing maintenance.
                    </p>
    
                </div>

            </div>

        </div>):null}

        </>
       
    )
}

export default CompressiveAdd