import React,{useState} from 'react'
import TransFromBusiness from '../services/TransFromBusiness'
import './career.scss'
import CareerBanner from './CareerBanner'
import WhyWork from './WhyWork'
import JoinVikgole from './JoinVikgole'
import PerfectPosition from './PerfectPosition'

function Careers() {

    const [message, setMessage] = useState('Join the vikgol team');
  return (
    <div className='container-fluid overflowHide px-0  mt-3 wow fadeInUp' data-wow-delay ="1s">
        <CareerBanner/>
         <WhyWork/>
        <JoinVikgole  someProp={message}  />
        <PerfectPosition/>
    </div>
  )
}

export default Careers