import Aboutus from "../view/aboutus/Aboutus";
import Blog from "../view/blog/Blog";
import BlogDetail from "../view/blog/BlogDetail";
import CareerDetail from "../view/careers/CareerDetail";
import Careers from "../view/careers/Careers";
import Contact from "../view/contact/Contact";
import Home from "../view/home/Home";
import Privacy from "../view/privacy/Privacy";
import ServiceDetail from "../view/services/ServiceDetail";
import Services from "../view/services/Services";
import TermsAndCondtion from "../view/termsandconditions/TermsAndCondtion";

export const PublicRoute =[
    {path:'/',component:<Home/>},
    {path:'/contact-us',component:<Contact/>},
    {path:'/services',component:<Services/>},
    {path:'/services/detail',component:<ServiceDetail/>},
    {path:'/about-us',component:<Aboutus/>},
    {path:'/careers',component:<Careers/>},
    {path:'/careers/detail',component:<CareerDetail/>},
    {path:'/blog',component:<Blog/>},
    {path :'/blog/detail',component:<BlogDetail/>},
    {path:'/privacyPolicy',component:<Privacy/>},
    {path:'/termsOfService',component:<TermsAndCondtion/>},


    

]