import React from 'react'
import { Iconcmimage } from '../../assets'

function HarnesingAI() {
    return (
        <div className='HarnesingAI wow fadeInUp' data-wow-delay ="0.5s">
            <div className='row'>

                <div className='col-md-8'>
                    <h2>Harnessing AI for Exceptional Digital Experiences</h2>

                    <p>We're at the forefront of web development, leveraging the power of Generative AI to create intelligent, personalized, and high-performing digital experiences. Our AI-driven approach transforms websites from static platforms into dynamic, adaptive ecosystems.</p>

                </div>
                <div className='col-md-4'>
                    <img src={Iconcmimage} alt="" />

                </div>

            </div>

        </div>
    )
}

export default HarnesingAI