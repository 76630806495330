import React from 'react'

function BlogBanner() {
  return (
    <div className='ContactPageBanner blogbanner wow fadeInUp' data-wow-delay="1s" >
    <div className='col-12 InsideBanner justify-content-center'>

        <p className='text-center d-block wow fadeInUp' data-wow-delay="1.7s">
        Your hub for industry news, trends, and insights
        </p>

    </div>
</div>
  )
}

export default BlogBanner