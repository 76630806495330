import React from 'react'

function AboutBanner() {
  return (
    <div className='ContactPageBanner Aboutbanner wow fadeInUp' data-wow-delay="1s" >
            <div className='col-12 InsideBanner justify-content-center'>

                <p className='wow fadeInUp' data-wow-delay="1.7s">
                Crafting innovative software solutions for a competitive edge.
                </p>

            </div>
        </div>
  )
}

export default AboutBanner