import React from 'react'

import './Contact.scss'

function ContactBanner() {
    return (
        <div className='ContactPageBanner wow fadeInUp' data-wow-delay="1s" >
            <div className='col-12 InsideBanner justify-content-center'>

                <p className='wow fadeInUp' data-wow-delay="1.7s" >
                    Have questions or need assistance?
                    Our team is here to help
                </p>

            </div>
        </div>
    )
}

export default ContactBanner