import React from 'react'

import Slider from 'react-slick';
import { BlogDes1, BlogDes2, BlogDes3, BlogDes4, SliderBlog1 } from '../../assets';

function BlogSlider() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        cssEase: "linear",
        customPaging: i => (
            <div className="custom-dot"></div>
              ),
              dotsClass: "slick-dots custom-dots"
       
    };


    return (
        <div className='BlogSlider wow fadeInUp '  data-wow-delay="0.5s">
            <h1>Our recent posts</h1>

            <Slider {...settings} className='mt-lg-4'>
                <div className="post-slide">
                    <div className='Content-wrap'>

                    <img src={BlogDes3} alt="Post 1" className="post-image w-100" />
                    <div className="post-content col-md-9">
                        
                        <h4 className="post-category">DEVELOPMENT</h4>
                        <p className="post-date">16 March 2023</p>
                        <h3 className="post-title">Setting Up Kubernetes with Kubespray</h3>
                        <p className="post-description">
                        Kubernetes automates container deployment and scaling; tools like Kubespray simplify setup and management complexities.

                        </p>
                    </div>

                    </div>
                   
                </div>
                <div className="post-slide">
                    <div className='Content-wrap'>

                    <img src={SliderBlog1} alt="Post 1" className="post-image w-100" />
                    <div className="post-content col-md-9">
                        
                        <h4 className="post-category">DEVELOPMENT</h4>
                        <p className="post-date">16 March 2023</p>
                        <h3 className="post-title">Creating a Cost-Efficient Jenkins Setup </h3>
                        <p className="post-description">
                        Jenkins simplifies CI/CD but is costly. AWS Spot Instances reduce costs. This guide sets up Jenkins cost-efficiently.

                        </p>
                    </div>

                    </div>
                   
                </div>
                <div className="post-slide">
                    <div className='Content-wrap'>

                    <img src={BlogDes4} alt="Post 1" className="post-image w-100" />
                    <div className="post-content col-md-9">
                        
                        <h4 className="post-category">DEVELOPMENT</h4>
                        <p className="post-date">16 March 2023</p>
                        <h3 className="post-title">Achieving High Availability on AWS </h3>
                        <p className="post-description">
                        Ensure high availability with AWS and Terraform. This guide shows how to build resilient, scalable infrastructure efficiently.

                        </p>
                    </div>

                    </div>
                   
                </div>
               
            </Slider>
        </div>
    )
}

export default BlogSlider
