import React from 'react'
import { Devops2, Dm2, Gen2, Mobiletech, Sw2, Tech, UiUx2, VisonPact, next } from '../../assets'
import { useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ServiceImpact() {

    const query = useQuery();
    const searchTerm = query.get('ServiceType');

    return (
        <>
            {searchTerm === 'web_app_development' ? (<div className='ServiceImpact '>

                <div className="container VisonImpact ServiceImpact  py-lg-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="row align-items-center ">
                        <div className="col-md-6">
                            <h1 className="font-weight-bold">The top & most recommended web development company</h1>
                            <p className="lead">
                                Vikgol is a leading web development agency in Bangalore, known for delivering exceptional UX-focused interfaces. We partner with brands to create powerful solutions that drive business growth.
                            </p>
                            <a href="https://calendly.com/harsh-46/30min?month=2024-08" target='_blank' className="btn btn-primary btn-visonimp serviceBtnimpact mt-lg-4  btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={next} alt="" /></a>
                        </div>
                        <div className="col-md-6 pt-lg-5 d-flex justify-content-center">
                            <img src={Tech} alt="Vikgol Journey" className="img-fluid vigole_img2 " />
                        </div>
                    </div>
                </div>

            </div>) : null}
            {searchTerm === 'mobile_app_development' ? (<div className='ServiceImpact '>

                <div className="container VisonImpact ServiceImpact  py-lg-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="row align-items-center ">
                        <div className="col-md-6">
                            <h1 className="font-weight-bold">Crafting exceptional mobile Experiences</h1>
                            <p className="lead">
                                Vikgol is a leading mobile app development company renowned for crafting exceptional user experiences. We partner with businesses to build high-performance apps that drive growth and engagement.
                            </p>
                            <a href="https://calendly.com/harsh-46/30min?month=2024-08" target='_blank' className="btn btn-primary btn-visonimp serviceBtnimpact mt-lg-4  btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={next} alt="" /></a>
                        </div>
                        <div className="col-md-6 pt-lg-5 d-flex justify-content-center">
                            <img src={Mobiletech} alt="Vikgol Journey" className="img-fluid vigole_img2 " />
                        </div>
                    </div>
                </div>

            </div>) : null}
            {searchTerm === 'devops' ? (<div className='ServiceImpact '>

                <div className="container VisonImpact ServiceImpact  py-lg-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="row align-items-center ">
                        <div className="col-md-6">
                            <h1 className="font-weight-bold">Accelerate your software delivery with DevOps</h1>
                            <p className="lead">
                                Our DevOps services bridge the gap between development and operations, streamlining your software delivery pipeline. By implementing robust DevOps practices, we help you achieve faster time-to-market, improved software quality, and enhanced operational efficiency.
                            </p>
                            <a href="https://calendly.com/harsh-46/30min?month=2024-08" target='_blank' className="btn btn-primary btn-visonimp serviceBtnimpact mt-lg-4  btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={next} alt="" /></a>
                        </div>
                        <div className="col-md-6 pt-lg-5 d-flex justify-content-center">
                            <img src={Devops2} alt="Vikgol Journey" className="img-fluid vigole_img2 " />
                        </div>
                    </div>
                </div>

            </div>) : null}
            {searchTerm === 'Ui-Ux-design' ? (<div className='ServiceImpact '>

                <div className="container VisonImpact ServiceImpact   pb-0 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="row align-items-center ">
                        <div className="col-md-6">
                            <h1 className="font-weight-bold">Create exceptional digital experiences through human-centered design</h1>
                            <p className="lead">
                                We create designs that not only look stunning but also deliver exceptional user experiences. Our focus on UX, UI, and CX ensures that your product is not just visually appealing but also intuitive and customer-centric.
                            </p>
                            <a href="https://calendly.com/harsh-46/30min?month=2024-08" target='_blank' className="btn btn-primary btn-visonimp serviceBtnimpact mt-lg-4  btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={next} alt="" /></a>
                        </div>
                        <div className="col-md-6 pt-lg-5 d-flex justify-content-center">
                            <img src={UiUx2} alt="Vikgol Journey" className="img-fluid vigole_img2 " />
                        </div>
                    </div>
                </div>

            </div>) : null}
            {searchTerm === 'digital_marketing' ? (<div className='ServiceImpact '>

                <div className="container VisonImpact ServiceImpact   py-lg-5 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="row align-items-center py-lg-5 ">
                        <div className="col-md-6">
                            <h1 className="font-weight-bold">Comprehensive marketing agency in Bangalore</h1>
                            <p className="lead">
                                We're a leading digital marketing agency in Bangalore, crafting innovative strategies for businesses to thrive in the digital age. Our data-driven approach and deep understanding of the local market deliver exceptional results.
                            </p>
                            <a href="https://calendly.com/harsh-46/30min?month=2024-08" target='_blank' className="btn btn-primary btn-visonimp serviceBtnimpact mt-lg-4  btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={next} alt="" /></a>
                        </div>
                        <div className="col-md-6 pt-lg-5 d-flex justify-content-end">
                            <img src={Dm2} alt="Vikgol Journey" className="img-fluid vigole_img2 " />
                        </div>
                    </div>
                </div>

            </div>) : null}
            {searchTerm === 'software_testing' ? (<div className='ServiceImpact '>

                <div className="container VisonImpact ServiceImpact   py-lg-5 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="row align-items-center  ">
                        <div className="col-md-6">
                            <h1 className="font-weight-bold">Vikgol software testing solution</h1>
                            <p className="lead">
                                Experience unparalleled quality and speed with our automation expertise. Harness the power of automation to achieve unmatched efficiency and elevate your productivity where precision meets excellence.
                            </p>
                            <a href="https://calendly.com/harsh-46/30min?month=2024-08" target='_blank' className="btn btn-primary btn-visonimp serviceBtnimpact mt-lg-4  btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={next} alt="" /></a>
                        </div>
                        <div className="col-md-6 pt-lg-5 d-flex justify-content-end">
                            <img src={Sw2} alt="Vikgol Journey" className="img-fluid vigole_img2 " />
                        </div>
                    </div>
                </div>

            </div>) : null}
            {searchTerm === 'Gen_AI' ? (<div className='ServiceImpact '>

                <div className="container VisonImpact ServiceImpact   py-lg-5 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="row align-items-center  ">
                        <div className="col-md-6">
                            <h1 className="font-weight-bold">Revolutionising conversations</h1>
                            <p className="lead">
                            Experience the future of customer engagement with our cutting-edge custom chatbot solutions. Powered by generative AI, our chatbots deliver personalized, intelligent, and efficient interactions that enhance customer satisfaction and drive business growth.
                            </p>
                            <a href="https://calendly.com/harsh-46/30min?month=2024-08" target='_blank' className="btn btn-primary btn-visonimp serviceBtnimpact mt-lg-4  btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={next} alt="" /></a>
                        </div>
                        <div className="col-md-6 pt-lg-5 d-flex justify-content-end">
                            <img src={Gen2} alt="Vikgol Journey" className="img-fluid vigole_img2 " />
                        </div>
                    </div>
                </div>

            </div>) : null}


        </>

    )
}

export default ServiceImpact