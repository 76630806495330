import React from 'react'
import './trans.scss';

function TermsAndCondtion() {
  return (
    <div className="terms-of-use wow fadeInUp" data-wow-delay ="0.5s">


    <h2>Terms and Conditions</h2>

    <section >
        <h3>Vikgol Terms of Use</h3>
      <p>By using the vikgol.com website or any products or services provided by Vikgol Technologies Pvt. Ltd., you agree to be bound by the following terms and conditions ("Terms of Use"). Please review these Terms of Use carefully, as they may be amended from time to time. The terms “You,” “Your,” “User,” or “Users” refer to anyone accessing our services or the Site for any reason, including, without limitation, a company or individual representing a business that registers to have its software solution offered on the Site (each, a “Company”). Your use of the Site is conditioned upon your acceptance of and compliance with these Terms of Use. Vikgol may amend or modify these Terms of Use at any time by updating this posting. Your continued use of the Site after any changes constitutes your agreement to be bound by all such amendments or modifications</p>
    </section>

    <section >
        <h3>Non-Disclosure of Confidential Information</h3>
      <p>We aim to create a transparent and fair environment for all Vikgal users. All users (customers and software solution providers) must use valid email addresses, real names, and agree not to disclose information about other Vikgal users. For example, logistic companies are prohibited from sharing information about Vikgal customers, their applications, or any data from Vikgal web properties on platforms such as Facebook™, Twitter™, blogs, forums, or other digital media.</p>
    </section>

    <section >
     <h3>I. GENERALLY APPLICABLE TERMS AND CONDITIONS</h3>
      <p>Vikgal provides a wide range of content, services, benefits, and resources on the Site (collectively, the “Site Content”). You are granted a non-exclusive, non-transferable, and non-assignable license to use and view the Site Content, subject to your compliance with these Terms of Use. Except as expressly permitted, modification, reproduction, redistribution, republication, uploading, posting, transmitting, distributing, or otherwise exploiting the Site Content or any portion thereof is strictly prohibited without prior written permission from Vikgal. Some Site Content may relate to legal, financial, or technical matters for informational purposes only. Users should verify such information with a professional in the relevant field.</p>
    </section>

    <section >
    <h3>Links to Third-Party Websites</h3>
      <p>The Site may contain links to third-party websites. We do not monitor or control these linked sites and are not responsible for their content. Links are provided for convenience only and do not imply endorsement or affiliation with the linked site by Vikgal. Users should conduct their own investigation before engaging with third parties.</p>
    </section>

    <section >
    <h3>Limitations of Liability and Disclaimers of Warranties</h3>
      <p>By using the Site, you agree to indemnify Vikgal against any third-party claims related to alleged infringement of intellectual property rights. Vikgal and its affiliates make no warranties regarding the Site or Site Content. The Site and Site Content are provided “AS IS,” and Vikgal expressly disclaims all warranties, including implied warranties of merchantability, fitness for a particular purpose, and non-infringement. Vikgal does not guarantee that the Site and Site Content will meet your requirements or be available, uninterrupted, secure, or error-free. You use the Site and Site Content at your own risk. Vikgal is not liable for any damages arising from the use of the Site or Site Content, including incidental, consequential, indirect, punitive, or special damages. Some jurisdictions may not allow these limitations, so some may not apply to you.</p>
    </section>

    <section >
        <h3>Site Restrictions</h3>
      <p>Users must not submit, upload, distribute, or post any material that (a) is libelous, defamatory, threatening, abusive, obscene, pornographic, or unlawful; (b) Contains advertising, promotional, or commercial material not directly related to a project on the Site; (c) Uses copyrighted material without consent, except for reasonable excerpts under fair use; (d) Infringes any intellectual property rights or privacy rights; (e) Involves statements or materials that could cause injury or damage; (f) Contains viruses or code designed to disrupt functionality.</p>
    </section>

    <section >
        <h3>Interactive Content</h3>
      <p>Vikgal reserves the right to: (a) Record interactive content; (b) Investigate allegations of non-conformity with these Terms of Use; (c) Remove abusive, illegal, or disruptive content; (d) Monitor, edit, or disclose interactive content; (e) Edit or delete content regardless of compliance with Terms of Use. Vikgal is not liable for the performance or non-performance of those activities. Users may not: (a) Use automated or manual processes to monitor or copy the Site content; (b) Contact employers or freelancers to discourage site use; (c) Engage in personal attacks or unfair criticism.</p>
    </section>

    <section >
    <h3>Eligibility</h3>
      <p>The Site is available only to individuals and entities that can form legally binding contracts under applicable law. Minors or those without legal authority to enter into contracts cannot use the Site. By registering as a Company, you represent that you have the authority to agree to these Terms of Use. Vikgal may terminate access to the Site without notice for any reason. Companies violating these Terms of Use forfeit rights to payment or refunds. Vikgal may pursue claims for direct damages to the extent of the law.</p>
    </section>

    <section >
    <h3>PROVISIONS APPLICABLE TO CUSTOMERS</h3>
      <p>Companies may purchase: (i) Subscriptions: Available on a monthly, quarterly, or annual basis. The subscription period begins upon signing the Order Form or Statement of Work, with payment due upon receipt of the invoice. Subscriptions may auto-renew and are cancellable after the minimum commitment with written notice to finance@vikgal.com. On-demand feature changes are available at a fixed fee and must be completed within the time specified.</p>
    </section>

    <section >
    <h4>OTHER GENERAL PROVISIONS APPLICABLE TO ALL USERS</h4>
   <h5>Rights in Site Content:</h5> <p> Unless otherwise indicated, Site Content, including images, illustrations, icons, designs, and other materials, are owned by Vikgal or its licensors and are protected by copyright and trademark laws. You are permitted to display, download, and print Site resources solely for personal or internal business use. Unauthorized copying, reproduction, distribution, or transmission of Site resources is prohibited.</p>
   <h5>User Experience:</h5> <p> User actions on Vikgal may be recorded to enhance user experience and ensure compliance with Terms of Service. We may take necessary actions to protect the security and functionality of the Site.</p>
   <h5>Dispute Resolution:</h5> <p> These Terms of Use are governed by the laws of Bangalore, India. Disputes involving Vikgal will be resolved exclusively in Bangalore courts. You release Vikgal from any claims or damages related to disputes with other users.</p>
   <h5>Use of Cookies:</h5><p> Cookies are used to manage sessions on our site and are not used to collect private information beyond what users intentionally provide.</p>
   <h5>Social Network Integration:</h5><p> Vikgal offers integration with social networks for connecting profiles. If you prefer not to share profile information, you should delete or not add your social network profiles. Communications through social networks are subject to limitations in this Agreement.</p>
   <h5>Changes to Privacy Policy:</h5><p> We may update our Privacy Policy from time to time. Changes will be posted on our site, and continued use of the Site signifies acceptance of the revised policy.</p>
   <h5>Transfer of Information:</h5><p> We reserve the right to transfer personal information in the event of ownership transfer or asset acquisition of Vikgal. Users will be notified of such transfers, and information will be subject to a new privacy policy.</p>
    </section>
  </div>
  )
}

export default TermsAndCondtion