import React, { useEffect,useState } from 'react'
import JoinVikgole from './JoinVikgole';
import CareerInfo from './CareerInfo';
import CareerDescription from './CareerDescription';


function CareerDetail() {

    const [message, setMessage] = useState('Other vacancies');

    useEffect(()=>{
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0); // You can adjust the timeout duration if needed
        return () => clearTimeout(timer);
    },[])
  return (
    <div className='container-fluid overflowHide px-0  mt-3 wow fadeInUp' data-wow-delay ="1s">
        <CareerInfo/>
        <CareerDescription/>
        <JoinVikgole
         someProp={message} 
         />

    </div>
  )
}

export default CareerDetail